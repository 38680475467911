import { LineChart, Line, XAxis, YAxis } from "recharts";
import { DeviceHistoryData } from "models/device";
import "./LineGraph.css";
export interface LineGraphProps {
  title: string;
  info: string;
  data: DeviceHistoryData[];
}

const LineGraph = ({ title, info, data }: LineGraphProps) => {
  const getTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  return (
    <div className="line-graph">
      <div className="line-graph-title">{title}</div>
      <div className="line-graph-info">{info}</div>
      <LineChart width={400} height={200} data={data}>
        <XAxis
          dataKey={"timestamp"}
          tickFormatter={(timestamp) => getTime(timestamp)}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          orientation="right"
          padding={{ top: 10, bottom: 10 }}
          ticks={[-100, -50, 0, 50, 100]}
        />
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
      </LineChart>
    </div>
  );
};

export default LineGraph;
