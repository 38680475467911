import { ConfirmDelete } from "../../components/ConfirmDelete";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { deleteCustomer } from "../../services/customers-api";

export interface DeleteCustomerDialogProps {
  customerId: number;
  setSelectedCustomerID: (customerId: number | undefined) => void;
  afterDelete?: () => void;
}

export const DeleteCustomerDialog = ({
  customerId,
  setSelectedCustomerID,
  afterDelete,
}: DeleteCustomerDialogProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const handleDeleteCustomer = async () => {
    if (customerId === undefined || !cliftState.currentTenant) return;

    await deleteCustomer(cliftState.currentTenant.id, customerId)
      .then(() => {
        afterDelete?.();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_delete_success", {
            ns: "alerts",
            customerId: customerId,
          }),
        });
        toast.success(
          t("customer_deleted", {
            ns: "toasts",
            customerId: customerId,
          })
        );
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_delete_failed", {
            ns: "alerts",
            customerId: customerId,
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("delete_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });

    setSelectedCustomerID(undefined);
  };
  return (
    <ConfirmDelete
      title={t("delete_customer_question", { ns: "dialogs" })}
      text={t("confirm_customer_delete_question", {
        ns: "dialogs",
        customer: customerId,
      })}
      isOpen={customerId !== undefined}
      onCancel={() => setSelectedCustomerID(undefined)}
      onConfirmed={handleDeleteCustomer}
    />
  );
};

export default DeleteCustomerDialog;
