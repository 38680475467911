import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { addDevicesToTenant } from "../../services/tenants-api";
import { DeviceRegistrationItem } from "../../models/device";
import SideModal from "../../components/SideModal";
import { AddLiftsForm } from "./AddLiftsForm";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import { toast } from "react-toast";

export interface AddTenantLiftsProps {
  tenantID: number;
}

export const AddTenantLifts = ({ tenantID }: AddTenantLiftsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [bottomButtonsProps, setBottomButtonsProps] =
    useState<SideModalButtonBarProps>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddLifts = (devices: DeviceRegistrationItem[]) => {
    addDevicesToTenant(tenantID, devices)
      .then(() => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("stairlift_add_to_tenant_success", {
            ns: "alerts",
            tenantId: tenantID,
          }),
        });
        toast.success(t("add_stairlift_success", { ns: "toasts" }));
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("stairlift_add_to_tenant_failed", {
            ns: "alerts",
            tenantId: tenantID,
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("add_stairlift_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={t("add_lifts_title", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      onClose={closeSidePanel}
    >
      <AddLiftsForm
        error={error}
        initialDevices={[]}
        onSubmit={onAddLifts}
        onClose={closeSidePanel}
        omitNickName={true}
        setBottomButtonsProps={setBottomButtonsProps}
      />
    </SideModal>
  );
};
