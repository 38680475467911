import { ChangeEvent, useState } from "react";
import { ReactNode, MouseEvent } from "react";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import "./List.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "../assets/Search.svg";
import { ReactComponent as FilterIcon } from "../assets/Filter.svg";
import { ReactComponent as DownIcon } from "../assets/Down.svg";
import { ReactComponent as ExportIcon } from "../assets/Export.svg";

export interface ListProps<T> {
  data: T[];
  columns: ColumnDef<T, string>[];
  noDataComponent?: ReactNode;
  showSearch?: boolean;
  onSearch?: (text: string) => void;
  onFilter?: () => void;
  onSort?: () => void;
  onExport?: () => void;
}

const List = <T,>({
  data,
  columns,
  noDataComponent,
  showSearch,
  onSearch,
  onFilter,
  onSort,
  onExport,
}: ListProps<T>) => {
  // const [data, setData] = useState<TData[]>([]);
  const [filtering, setFiltering] = useState<string>();
  const { t } = useTranslation();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter: filtering,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
  });

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSearch?.(value);
  };

  const handleFilter = (e: MouseEvent) => {
    e.preventDefault();
    onFilter?.();
  };

  const handleSort = (e: MouseEvent) => {
    e.preventDefault();
    onSort?.();
  };

  const handleExport = (e: MouseEvent) => {
    e.preventDefault();
    onExport?.();
  };

  return (
    <div className="list">
      <div className="list-tools">
        {onSearch && (
          <div className="list-search">
            <input
              type="search"
              placeholder={t("search_placeholder", { ns: "list" })}
              defaultValue=""
              onChange={handleSearchChange}
            />
            <SearchIcon className="list-search-icon" />
          </div>
        )}
        {showSearch && (
          <div className="list-search">
            <input
              type="search"
              placeholder={t("search_placeholder", { ns: "list" })}
              value={filtering}
              onChange={(e) => {
                setFiltering(e.target.value);
              }}
            />
            <SearchIcon className="list-search-icon" />
          </div>
        )}
        <div className="list-buttons">
          {onFilter && (
            <button onClick={handleFilter} type="button">
              <span>
                {t("filter", { ns: "list" })}
                <FilterIcon />
              </span>
            </button>
          )}
          {onSort && (
            <button onClick={handleSort} type="button">
              <span>
                {t("sort", { ns: "list" })}
                <DownIcon />
              </span>
            </button>
          )}
          {onExport && (
            <button onClick={handleExport} type="button" title="export">
              <ExportIcon />
            </button>
          )}
        </div>
      </div>

      <div className="list-content">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {!table.getRowModel().rows.length && (
              <tr>
                <td colSpan={table.getVisibleFlatColumns().length}>
                  {noDataComponent ? (
                    noDataComponent
                  ) : (
                    <div className="list-no-data">
                      {t("no-data", { ns: "list" })}
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default List;
