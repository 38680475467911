import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { isRoleForAction } from "../../../hooks/useRoles";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { Device } from "../../../models/device";
import { LiftSipEditAllowedRoles } from "../../../models/role";

export interface SipSettingsCardProps {
  device: Device;
}

export const SipSettingsCard = ({ device }: SipSettingsCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditSipSettings,
      tenantID: cliftState.currentTenant.id,
      device,
    });
  };
  const { t } = useTranslation();

  return (
    <Card
      title={t("sip_settings", { ns: "lift" })}
      editAllowed={isRoleForAction(LiftSipEditAllowedRoles)}
      onEdit={handleEdit}
    >
      <CardDetailTitled
        title={t("sip_registrar", { ns: "lift" })}
        value={device.sipSettings?.registrar}
      />
      <CardDetailTitled
        title={t("sip_realm", { ns: "lift" })}
        value={device.sipSettings?.realm}
      />
      <CardDetailTitled
        title={t("sip_username", { ns: "lift" })}
        value={device.sipSettings?.username}
      />
      <CardDetailTitled
        title={t("sip_password", { ns: "lift" })}
        value={device.sipSettings?.password}
        type="password"
      />
    </Card>
  );
};
