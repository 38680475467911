import { useCallback } from "react";
import { downloadFile, fetchCsv } from "../services/csv-api";
import { toast } from "react-toast";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "./useCliftContext";
import { CliftReducerAction } from "../context/clift-context/clift-action-types";

interface CsvExportParams {
  tableName: string;
  tenantId: number;
}

/**
 * Hook for exporting table data as CSV.
 *
 * @returns {(params: CsvExportParams) => Promise<void>} A function to handle CSV export.
 */
export const useCsvExport = () => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();
  /**
   * Handles CSV export for table data.
   *
   * @param {CsvExportParams} params - Parameters for CSV export.
   */
  const handleExport = useCallback(
    async ({ tableName, tenantId }: CsvExportParams): Promise<void> => {
      const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      try {
        // Fetch CSV Blob from the backend
        const csvBlob = await fetchCsv(tableName, tenantId);

        // Trigger browser download
        const filename = `${tableName}-${new Date().toISOString()}.csv`;
        downloadFile(csvBlob, filename);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("file_export_success", {
            ns: "alerts",
            name: capitalizeFirstLetter(tableName),
          }),
        });
        toast.success(t("export_completed", { ns: "toasts" }));
      } catch (error) {
        console.error("Error exporting CSV:", error);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("file_export_failed", {
            ns: "alerts",
            name: capitalizeFirstLetter(tableName),
          }),
        });
        toast.error(
          t("error") +
            t("export_failed", {
              ns: "toasts",
            })
        );
      }
    },
    []
  );

  return handleExport;
};
