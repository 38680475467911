import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "react-datetime-picker";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import SideModal from "../../../components/SideModal";
import { Device, DeviceDetails } from "../../../models/device";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import FormField from "../../../components/FormField";
import "./DeviceDetailsForm.css";
import { updateDeviceDetails } from "../../../services/devices-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../../services/clift-api-errors";
import { toast } from "react-toast";

export interface DeviceDetailsFormProps {
  device: Device;
  deviceID: number;
}

const DEFAULT_LOCATION = {
  address: "",
  apartmentNr: "",
  zipCode: "",
  city: "",
  region: null,
  country: "",
};

const DEFAULT_DEVICE_DETAILS = {
  nickname: "",
  warrantyStartDate: "",
  warrantyEndDate: "",
  subscriptionStartDate: "",
  subscriptionEndDate: "",
  location: DEFAULT_LOCATION,
};

export const DeviceDetailsForm = ({ device }: DeviceDetailsFormProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>(
    device.deviceDetails
  );
  const [hasChanged, setChanged] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [openCalendarIndex, setOpenCalendarPicker] = useState<number | null>(
    null
  );
  const modalContentContainerRef = useRef<HTMLDivElement | null>(null);
  const elementRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    setDeviceDetails(
      device.deviceDetails ? device.deviceDetails : DEFAULT_DEVICE_DETAILS
    );
  }, [device.deviceDetails]);

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  useEffect(() => {
    if (openCalendarIndex === null || !modalContentContainerRef.current) return;

    const containerElement = elementRefs.current[
      openCalendarIndex
    ] as HTMLDivElement;

    const calendarHeight = 332;
    const bottomButtonsHeight = 160;

    const rect = containerElement.getBoundingClientRect();
    const availableSpaceBelow =
      window.innerHeight - rect.bottom - bottomButtonsHeight;

    if (availableSpaceBelow < calendarHeight) {
      const scrollAmount = calendarHeight - availableSpaceBelow;
      modalContentContainerRef.current.scrollBy({
        top: scrollAmount,
        behavior: "smooth",
      });
    }
  }, [openCalendarIndex]);

  const onValueChanged = (
    name: string,
    value: string | number | Date | null
  ) => {
    setChanged(true);
    setDeviceDetails({ ...deviceDetails, [name]: value as string });
  };

  const onAddressValueChanged = (
    name: string,
    value: string | number | null
  ) => {
    setChanged(true);
    setDeviceDetails({
      ...deviceDetails,
      location: { ...deviceDetails.location, [name]: value as string },
    });
  };

  const onSubmit = (device: Device) => {
    updateDeviceDetails(
      cliftState?.currentTenant?.id || 0,
      device.id,
      deviceDetails
    )
      .then(() => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_details_update_success", {
            ns: "alerts",
            deviceId: device.id,
          }),
        });
        toast.success(
          t("changes_saved", {
            ns: "toasts",
          })
        );
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_details_update_failed", {
            ns: "alerts",
            deviceId: device.id,
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("edit_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  const onSave = () => {
    onSubmit(device);
  };

  const onClose = () => {
    if (hasChanged) {
      setCancelled(true);
    } else {
      closeSidePanel();
    }
  };

  const bottomButtonsProps = {
    onCancel: onClose,
    showSave: true,
    onSave: onSave,
  };

  return (
    <SideModal
      title={t("edit_stairlift_details", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      contentContainerRef={modalContentContainerRef}
      onClose={onClose}
    >
      <div className="device-details-form">
        <form>
          <div className="device-details-form-content">
            <div className="device-details-form-serial-number">
              <FormField
                title={t("x06_serial_number", { ns: "lift" })}
                name="serialNumber"
                disabled
                value={device.serialNumber}
                hintText={t("defined_when_added", { ns: "lift" })}
              />
            </div>
            <div className="device-details-form-nickname">
              <FormField
                title={t("lift_name_or_model", { ns: "lift" })}
                name="nickname"
                value={deviceDetails.nickname ?? ""}
                onValueChanged={(name, value) => onValueChanged(name, value)}
                hintText={t("personalize_name", { ns: "lift" })}
              />
            </div>

            <div className="device-details-form-date-picker-section">
              <div className="device-details-form-section-title">
                {t("warranty_information", { ns: "lift" })}
              </div>

              <div className="device-details-form-date-picker-row">
                <div className="device-details-form-date-picker-column">
                  <div className="device-details-form-date-picker-label">
                    {t("start_date", { ns: "lift" })}
                  </div>
                  <div
                    className="device-details-form-date-picker-container"
                    ref={(el) => (elementRefs.current[0] = el)}
                  >
                    <DateTimePicker
                      className={"device-details-form-date-picker"}
                      name="warrantyStartDate"
                      onChange={(date: Date | null) => {
                        onValueChanged("warrantyStartDate", date);
                      }}
                      value={deviceDetails.warrantyStartDate}
                      format="dd/MM/yyyy"
                      onCalendarOpen={() => setOpenCalendarPicker(0)}
                      onCalendarClose={() => setOpenCalendarPicker(null)}
                    />
                  </div>
                </div>

                <div className="device-details-form-date-picker-column">
                  <div className="device-details-form-date-picker-label">
                    {t("end_date", { ns: "lift" })}
                  </div>
                  <div
                    className="device-details-form-date-picker-container"
                    ref={(el) => (elementRefs.current[1] = el)}
                  >
                    <DateTimePicker
                      className={"device-details-form-date-picker"}
                      name="warrantyEndDate"
                      onChange={(date: Date | null) => {
                        onValueChanged("warrantyEndDate", date);
                      }}
                      value={deviceDetails.warrantyEndDate}
                      format="dd/MM/yyyy"
                      onCalendarOpen={() => setOpenCalendarPicker(1)}
                      onCalendarClose={() => setOpenCalendarPicker(null)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="device-details-form-date-picker-section">
              <div className="device-details-form-section-title">
                {t("subscription_validity", { ns: "lift" })}
              </div>

              <div className="device-details-form-date-picker-row">
                <div className="device-details-form-date-picker-column">
                  <div className="device-details-form-date-picker-label">
                    {t("start_date", { ns: "lift" })}
                  </div>
                  <div
                    className="device-details-form-date-picker-container"
                    ref={(el) => (elementRefs.current[2] = el)}
                  >
                    <DateTimePicker
                      className={"device-details-form-date-picker"}
                      name="subscriptionStartDate"
                      onChange={(date: Date | null) => {
                        onValueChanged("subscriptionStartDate", date);
                      }}
                      value={deviceDetails.subscriptionStartDate}
                      format="dd/MM/yyyy"
                      onCalendarOpen={() => setOpenCalendarPicker(2)}
                      onCalendarClose={() => setOpenCalendarPicker(null)}
                    />
                  </div>
                </div>

                <div className="device-details-form-date-picker-column">
                  <div className="device-details-form-date-picker-label">
                    {t("end_date", { ns: "lift" })}
                  </div>
                  <div
                    className="device-details-form-date-picker-container"
                    ref={(el) => (elementRefs.current[3] = el)}
                  >
                    <DateTimePicker
                      className={"device-details-form-date-picker"}
                      name="subscriptionEndDate"
                      onChange={(date: Date | null) => {
                        onValueChanged("subscriptionEndDate", date);
                      }}
                      value={deviceDetails.subscriptionEndDate}
                      format="dd/MM/yyyy"
                      onCalendarOpen={() => setOpenCalendarPicker(3)}
                      onCalendarClose={() => setOpenCalendarPicker(null)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="device-details-form-address-section">
              <div className="device-details-form-section-title">
                {t("stairlift_location", { ns: "lift" })}
              </div>

              <div className="device-details-form-address-section-row">
                <FormField
                  title={t("street_address", { ns: "lift" })}
                  name="address"
                  value={deviceDetails.location.address ?? ""}
                  onValueChanged={(name, value) =>
                    onAddressValueChanged(name, value)
                  }
                />
                <FormField
                  title={t("apartment_nr", { ns: "customer" })}
                  name="apartmentNr"
                  value={deviceDetails.location.apartmentNr ?? ""}
                  onValueChanged={(name, value) =>
                    onAddressValueChanged(name, value)
                  }
                />
              </div>
              <div className="device-details-form-address-section-row">
                <FormField
                  title={t("city", { ns: "customer" })}
                  name="city"
                  value={deviceDetails.location.city ?? ""}
                  onValueChanged={(name, value) =>
                    onAddressValueChanged(name, value)
                  }
                />
                <FormField
                  title={t("zip_code", { ns: "customer" })}
                  name="zipCode"
                  value={deviceDetails.location.zipCode ?? ""}
                  onValueChanged={(name, value) =>
                    onAddressValueChanged(name, value)
                  }
                />
              </div>
            </div>
          </div>
        </form>
        <ConfirmDialog
          isOpen={cancelled}
          onCancel={() => {
            setCancelled(false);
          }}
          onConfirm={() => {
            closeSidePanel();
          }}
        />
      </div>
    </SideModal>
  );
};
