import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { countries } from "countries-list";
import { useTooltip } from "../hooks/useTooltip";
import { ReactComponent as DownArrowIcon } from "../assets/Down.svg";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import "./PhoneNumberField.css";

export interface PhoneNumberFieldProps {
  title: string;
  name: string;
  value: string | null;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (name: string, value: string) => void;
}

interface Country {
  code: string;
  name: string;
  dialCode: string;
  flag: string;
}

const DEFAULT_COUNTRY = {
  code: "GB",
  name: "United Kingdom",
  dialCode: "44",
  flag: "https://flagcdn.com/w320/gb.png",
};

const PhoneNumberField = ({
  title,
  name,
  value,
  required,
  disabled,
  handleChange,
}: PhoneNumberFieldProps) => {
  const { t } = useTranslation();
  const { tooltipVisible, tooltipPosition, elementRef } = useTooltip();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [selectedCountry, setSelectedCountry] =
    useState<Country>(DEFAULT_COUNTRY);
  const [showOptions, setShowOptions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLDivElement>(null);

  const countryList = Object.entries(countries).map(([key, value]) => ({
    code: key,
    name: value.name,
    dialCode: `${value.phone[0]}`,
    flag: `https://flagcdn.com/w320/${key.toLowerCase()}.png`,
  }));

  const parsePhoneNumber = (value: string) => {
    const hasCountryCode = value.startsWith("+");
    const parsedPhone = hasCountryCode
      ? parsePhoneNumberFromString(value)
      : parsePhoneNumberFromString(value, DEFAULT_COUNTRY.code as CountryCode);

    return parsedPhone;
  };

  useEffect(() => {
    if (!value) {
      setPhoneNumber("");
    } else {
      const parsedPhoneFromString = parsePhoneNumber(value);

      if (parsedPhoneFromString) {
        const country = countryList.find(
          (country) => country.code === parsedPhoneFromString.country
        );
        setPhoneNumber(parsedPhoneFromString.nationalNumber);

        if (country) {
          setSelectedCountry(country);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (!phoneNumberRef.current || !listRef.current) return;

    const containerElement = phoneNumberRef.current as HTMLDivElement;
    const bottomButtonsHeight = 160;
    const listElement = listRef.current;
    const rect = containerElement.getBoundingClientRect();
    const availableSpaceBelow =
      window.innerHeight - rect.bottom - bottomButtonsHeight;

    if (!listElement) return;

    const listHeight = listElement.scrollHeight;
    const maxAllowedHeight = 460;
    const newHeight = Math.min(
      listHeight,
      availableSpaceBelow,
      maxAllowedHeight
    );

    listElement.style.maxHeight = `${Math.max(newHeight, 100)}px`;
    listElement.style.overflowY = "auto";
  }, [showOptions]);

  const getInitialHighlightedIndex = (selectedCountry: Country) => {
    return countryList.findIndex(
      (country) => country.code === selectedCountry.code
    );
  };

  useEffect(() => {
    if (!searchQuery) {
      const initialIndex = getInitialHighlightedIndex(selectedCountry);
      setHighlightedIndex(initialIndex >= 0 ? initialIndex : 0);
    }
  }, [searchQuery, selectedCountry]);

  useEffect(() => {
    if (showOptions && buttonRef.current) {
      const listElement = listRef.current;
      const highlightedElement = listElement?.children[
        highlightedIndex
      ] as HTMLElement;

      if (highlightedElement && listElement) {
        const listTop = listElement.scrollTop;
        const listHeight = listElement.offsetHeight;
        const itemTop = highlightedElement.offsetTop;
        const itemHeight = highlightedElement.offsetHeight;

        if (itemTop < listTop) {
          listElement.scrollTop = itemTop;
        } else if (itemTop + itemHeight > listTop + listHeight) {
          listElement.scrollTop = itemTop + itemHeight - listHeight;
        }
      }
    }
  }, [highlightedIndex, showOptions]);

  useEffect(() => {
    if (showOptions && highlightedIndex !== -1 && listRef.current) {
      const listElement = listRef.current;
      const highlightedElement = listElement.children[
        highlightedIndex
      ] as HTMLElement;
      if (!highlightedElement) return;

      highlightedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [showOptions, highlightedIndex]);

  const handleCloseOptionList = () => {
    setShowOptions(false);
    setSearchQuery("");
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);

    const newPhoneNumber = `+${selectedCountry.dialCode}${e.target.value}`;
    handleChange?.(name, newPhoneNumber);
  };

  const handleSelectCountry = (country: Country) => {
    setSelectedCountry(country);
    setHighlightedIndex(countryList.findIndex((c) => c.code === country.code));
    handleCloseOptionList();
    const newPhoneNumber = `+${country.dialCode}${phoneNumber}`;
    handleChange?.(name, newPhoneNumber);
  };

  const handleShowOptions = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  const isError =
    required && !phoneNumber?.trim() && !isFocused && !showOptions;

  const filteredCountries = searchQuery
    ? countryList.filter((country) =>
        country.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
    : countryList;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!showOptions) return;

      if (!searchQuery && e.key === "Backspace") {
        return;
      }

      switch (e.key) {
        case "Escape":
          handleCloseOptionList();
          setHighlightedIndex(-1);
          break;
        case "Enter":
          if (highlightedIndex >= 0 && filteredCountries[highlightedIndex]) {
            handleSelectCountry(filteredCountries[highlightedIndex]);
            handleCloseOptionList();
          }
          break;
        case "ArrowDown":
          e.preventDefault();
          setHighlightedIndex((prev) =>
            Math.min(prev + 1, filteredCountries.length - 1)
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setHighlightedIndex((prev) => Math.max(prev - 1, 0));
          break;
        case "Backspace":
          setSearchQuery((prev) => prev.slice(0, prev.length - 1));
          setHighlightedIndex(0);
          break;
        default:
          if (e.key.length === 1) {
            setSearchQuery((prev) => prev + e.key);
            setHighlightedIndex(0);
          }
      }
    },
    [showOptions, highlightedIndex, filteredCountries]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="phone-number-field-container">
      <div className={`phone-number-field-label ${disabled ? "disabled" : ""}`}>
        {title}
      </div>
      <div
        className={`phone-number-field  ${isError ? "error" : ""} ${disabled ? "disabled" : ""}`}
        ref={phoneNumberRef}
      >
        <div
          className="phone-number-field-picker"
          onMouseLeave={handleCloseOptionList}
        >
          <button
            className="phone-number-field-picker-button"
            onClick={(e) => handleShowOptions(e)}
            disabled={disabled}
          >
            {selectedCountry ? (
              <div
                className="phone-number-field-flag"
                style={{ backgroundImage: `url(${selectedCountry.flag})` }}
              ></div>
            ) : (
              <div className="flag-placeholder"></div>
            )}
            <DownArrowIcon />
          </button>

          {showOptions && (
            <div
              className="phone-number-field-options-list"
              ref={listRef}
              id="phoneList"
            >
              {searchQuery && <input value={searchQuery} readOnly />}

              {filteredCountries.length === 0 ? (
                <span className="phone-number-field-options-list-no-results">
                  {t("no_results", { ns: "translation" })}
                </span>
              ) : (
                filteredCountries.map((country, index) => (
                  <button
                    ref={
                      selectedCountry?.code === country.code ? buttonRef : null
                    }
                    key={country.code}
                    onClick={() => {
                      handleSelectCountry(country);
                      setShowOptions(false);
                      setSearchQuery("");
                      setHighlightedIndex(-1);
                    }}
                    className={`phone-number-field-option-item
                  ${selectedCountry?.code === country.code ? "selected" : ""}
                  ${highlightedIndex === index ? "highlighted" : ""}`}
                  >
                    <span>{`${country.name} [+${country.dialCode}]`}</span>
                  </button>
                ))
              )}
            </div>
          )}
        </div>

        <div className="phone-number-field-input">
          <div className="phone-number-field-input-code-placeholder">
            {`+${selectedCountry.dialCode}`}
          </div>
          <input
            data-testid="phone-nr-input"
            ref={(node) => {
              elementRef.current = node;
            }}
            type="tel"
            value={phoneNumber}
            required={required}
            onChange={handlePhoneNumberChange}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            disabled={disabled}
          />
        </div>
      </div>
      {tooltipVisible && (
        <div
          className="tooltip"
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          {`+${selectedCountry.dialCode}${phoneNumber}`}
        </div>
      )}
    </div>
  );
};

export default PhoneNumberField;
