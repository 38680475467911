import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Card, {
  CardDetail,
  CardDetailTitled,
  CardType,
} from "../../../components/Card";
import Tab from "../../../components/Tab";
import List from "../../../components/List";
import { Direction, Sort } from "../../../models/search";
import { ReactComponent as DownIcon } from "../../../assets/ArrowDown.svg";
import { ReactComponent as UpIcon } from "../../../assets/ArrowUp.svg";
import { ReactComponent as GreenLineIcon } from "../../../assets/GreenVerticalLine.svg";
import {
  DeviceHistoryData,
  DeviceLocation,
  DeviceState,
} from "../../../models/device";
import { useCliftContext } from "../../../hooks/useCliftContext";
import {
  fetchDeviceState,
  fetchHistoryData,
} from "../../../services/devices-api";
import { getErrorCode } from "../../../services/clift-api-errors";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import "./LiftStateCard.css";

enum EventSortableColumns {
  TIME = "TIME",
}

export interface LiftStateCardProps {
  deviceId: number | undefined;
}

const LiftStateCard = ({ deviceId }: LiftStateCardProps) => {
  const { t, i18n } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [locationHistoryData, setLocationHistoryData] =
    useState<DeviceHistoryData[]>();
  const [deviceState, setDeviceState] = useState<DeviceState>();
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: EventSortableColumns.TIME,
  });
  const [deviceStateLoading, setDeviceStateLoading] = useState<boolean>(false);
  const [fetchDeviceStateError, setFetchDeviceStateError] =
    useState<boolean>(false);

  const updateDeviceState = () => {
    if (!cliftState.currentTenant?.id || !deviceId) {
      return;
    }
    setDeviceStateLoading(true);
    fetchDeviceState(cliftState.currentTenant?.id, deviceId)
      .then((state) => {
        setFetchDeviceStateError(false);
        setDeviceStateLoading(false);
        const currentLocationTimestamp = beautifyDate(
          state.currentLocationTimestamp
        );
        if (state.faultCode === null || state.faultCode === "null") {
          state.faultCode = t("no_fault_code", { ns: "lift" });
        }
        setDeviceState({ ...state, currentLocationTimestamp });
      })
      .catch((err) => {
        setDeviceStateLoading(false);
        setFetchDeviceStateError(true);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  const fetchDeviceHistoryData = () => {
    if (!cliftState.currentTenant?.id || !deviceId) return;
    return fetchHistoryData(cliftState.currentTenant?.id, deviceId)
      .then((res) => {
        setLocationHistoryData(
          res.filter((data) => data.key === "currentLocation")
        );
      })
      .catch((err) => {
        setLocationHistoryData([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    fetchDeviceHistoryData();
    updateDeviceState();
  }, [deviceId, cliftState.currentTenant]);

  const onRefreshData = () => {
    fetchDeviceHistoryData();
    updateDeviceState();
  };

  const columnHelper = createColumnHelper<DeviceHistoryData>();

  const handleSort = (column: EventSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const getSortableColumnHeader = (
    column: EventSortableColumns,
    title: string
  ) => {
    const activeColumn = sort.column === column;
    return (
      <div className="sortable-header" onClick={() => handleSort(column)}>
        {title}
        <div className="sortable-header-arrows">
          <UpIcon
            className={`${activeColumn && sort.direction === Direction.ASC ? "active-arrow" : ""} `}
          />
          <DownIcon
            className={`${activeColumn && sort.direction === Direction.DESC ? "active-arrow" : ""} `}
          />
        </div>
      </div>
    );
  };

  const getLocalizedLocation = (location: string): string => {
    if (i18n.exists(location, { ns: "lift" })) {
      return i18n.t(location as DeviceLocation, { ns: "lift" });
    }
    return i18n.t("UNKNOWN", { ns: "lift" });
  };

  const beautifyDate = (date: string) => {
    if (!date) {
      return "-";
    }
    return new Date(date).toLocaleString();
  };

  const columns: ColumnDef<DeviceHistoryData, string>[] = useMemo(
    () => [
      columnHelper.accessor("timestamp", {
        header: () =>
          getSortableColumnHeader(
            EventSortableColumns.TIME,
            t("time", { ns: "lift" })
          ),
        cell: (info) => beautifyDate(info.getValue()),
      }),
      columnHelper.accessor("value", {
        header: t("description", { ns: "lift" }),
        cell: (info) => getLocalizedLocation(info.getValue()),
      }),
    ],
    [i18n.language, locationHistoryData, sort]
  );

  const tabs = [
    {
      name: t("events", { ns: "lift" }),
      content: (
        <div className="lift-state-card-events-tab">
          {fetchDeviceStateError && !deviceStateLoading && (
            <CardDetail
              value={t("lift_state_fetch_failed", { ns: "lift" })}
            ></CardDetail>
          )}
          {deviceState && !deviceStateLoading && (
            <div className="lift-state-card-events-tab-event">
              <div className="lift-state-card-events-tab-event-title">
                {t("latest_event", { ns: "lift" })}
              </div>

              <div className="lift-state-card-evens-tab-last-event">
                <GreenLineIcon />
                <div className="lift-state-card-evens-tab-last-event-content ">
                  <div className="lift-state-card-evens-tab-last-event-info">
                    <div className="lift-state-card-evens-tab-last-event-info-title">
                      {deviceState?.currentLocation ??
                        t("UPPER_STOP_POINT", { ns: "lift" })}
                    </div>
                  </div>
                  <div className="lift-state-card-evens-tab-last-event-details">
                    <div className="lift-state-card-evens-tab-last-event-details-title">
                      {t("last_updated", { ns: "lift" })}
                    </div>
                    <div className="lift-state-card-evens-tab-last-event-details-info">
                      {deviceState?.currentLocationTimestamp ?? "-"}
                    </div>
                  </div>
                </div>
              </div>
              <CardDetailTitled
                key="foot_rest_position"
                title={t("foot_rest_position", { ns: "lift" })}
                value={deviceState?.footRestPosition ?? "UNKNOWN"}
              />
              <CardDetailTitled
                key="seat_position"
                title={t("seat_position", { ns: "lift" })}
                value={deviceState?.seatPosition ?? "UNKNOWN"}
              />
              <CardDetailTitled
                key="hinge_position"
                title={t("hinge_position", { ns: "lift" })}
                value={deviceState?.hingePosition ?? "UNKNOWN"}
              />
              <CardDetailTitled
                key="swivel_position"
                title={t("swivel_position", { ns: "lift" })}
                value={deviceState?.swivelPosition ?? "UNKNOWN"}
              />
              <CardDetailTitled
                key="seatbelt_status"
                title={t("seatbelt_status", { ns: "lift" })}
                value={String(deviceState?.seatbeltStatus) ?? "UNKNOWN"}
              />
              <CardDetailTitled
                key="fault_code"
                title={t("fault_code", { ns: "lift" })}
                value={deviceState?.faultCode ?? "UNKNOWN"}
              />
            </div>
          )}

          <div className="lift-state-card-events-tab-history">
            <div className="lift-state-card-events-tab-history-title">
              <h1>{t("event_history", { ns: "lift" })}</h1>
              <h2>{t("events_from_past_month", { ns: "lift" })}</h2>
            </div>
            <List
              data={locationHistoryData ?? []}
              columns={columns}
              onExport={() => {}}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Card
      title={t("stairlift_state", { ns: "lift" })}
      subtitle={t("current_information_and_history", { ns: "lift" })}
      onRefresh={onRefreshData}
      cardType={CardType.DOUBLE}
      footerShadow={true}
    >
      <Tab tabs={tabs} />
    </Card>
  );
};

export default LiftStateCard;
