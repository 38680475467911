import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import Card, { CardAddressInfo } from "../../../components/Card";
import { Device } from "../../../models/device";
import { DeviceStateChip } from "./DeviceStateChip";
import DeviceStateInfo from "./DeviceStateInfo";
import DeviceImg from "../../../assets/StannahDevice.png";
import FormField from "../../../components/FormField";
import { useTooltip } from "../../../hooks/useTooltip";
import "./DeviceInfoCard.css";
import { isRoleForAction } from "../../../hooks/useRoles";
import { LiftDetailsEditAllowedRoles } from "../../../models/role";

export interface DeviceInfoCardProps {
  device: Device;
}

const DEFAULT_LOCATION = {
  address: "",
  apartmentNr: "",
  zipCode: "",
  city: "",
  region: null,
  country: "",
};

const DEFAULT_DEVICE_DETAILS = {
  nickname: "",
  warrantyStartDate: null,
  warrantyEndDate: null,
  subscriptionStartDate: null,
  subscriptionEndDate: null,
  location: DEFAULT_LOCATION,
};

export const DeviceInfoCard = ({ device }: DeviceInfoCardProps) => {
  const { t } = useTranslation();
  const { dispatchCliftState, cliftState } = useCliftContext();
  const { tooltipVisible, tooltipPosition, elementRef } = useTooltip();

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditDevice,
      device: device,
      deviceID: device.id,
    });
  };

  const getLocalDate = (dateString: string | null) => {
    if (!dateString || dateString.length === 0) return "";
    return new Date(dateString).toLocaleDateString();
  };

  const TitleWithImage = (
    <div className="device-info-card-title-section">
      <div className="device-info-card-title-section-image">
        <img src={DeviceImg} alt="Stairlift" />
      </div>
      <div className="device-info-card-title-section-content">
        <div className="device-info-card-title-section-row">
          <div
            ref={elementRef}
            className="device-info-card-title-section-title"
          >
            {device.contactInfo?.nickname
              ? device.contactInfo?.nickname
              : device.model}
          </div>
          {tooltipVisible && (
            <div
              className="tooltip"
              style={{
                left: `${tooltipPosition.x}px`,
                top: `${tooltipPosition.y}px`,
                zIndex: 9999,
              }}
            >
              {device.contactInfo?.nickname
                ? device.contactInfo.nickname
                : device.model}
            </div>
          )}
        </div>
        <div className="device-info-card-title-section-subscription-state">
          <DeviceStateChip
            subscriptionState={device.deviceSettings.subscriptionState}
          />
        </div>
        <FormField
          title={t("x06_serial_number", { ns: "lift" })}
          name="serial number"
          disabled
          value={device.serialNumber}
        />
      </div>
    </div>
  );

  return (
    <Card
      title={TitleWithImage}
      editAllowed={isRoleForAction(LiftDetailsEditAllowedRoles)}
      onEdit={handleEdit}
    >
      <DeviceStateInfo device={device} />
      <div className="device-info-card-info">
        <div className="device-info-card-info-title">
          {t("warranty_period", { ns: "lift" })}
        </div>
        <div className="device-info-card-info-data">
          {`${getLocalDate(device.deviceDetails?.warrantyStartDate || DEFAULT_DEVICE_DETAILS.warrantyStartDate)} - ${getLocalDate(device.deviceDetails?.warrantyEndDate || DEFAULT_DEVICE_DETAILS.warrantyEndDate)}`}
        </div>
      </div>

      <div className="device-info-card-info">
        <div className="device-info-card-info-title">
          {t("subscription_validity", { ns: "lift" })}
        </div>
        <div className="device-info-card-info-data">
          {`${getLocalDate(device.deviceSettings?.subscriptionStartDate || DEFAULT_DEVICE_DETAILS.subscriptionStartDate)} - ${getLocalDate(device.deviceSettings?.subscriptionEndDate || DEFAULT_DEVICE_DETAILS.subscriptionEndDate)}`}
        </div>
      </div>
      <div className="">
        <div className="device-info-card-info-title">
          {t("stairlift_location", { ns: "lift" })}
        </div>
        <div className=" device-info-card-info-data-location">
          <CardAddressInfo
            useHeader={false}
            street={
              device.deviceDetails?.location?.address
                ? device.deviceDetails.location.address
                : DEFAULT_DEVICE_DETAILS.location.address
            }
            apartmentNr={
              device.deviceDetails?.location?.apartmentNr
                ? device.deviceDetails.location.apartmentNr
                : DEFAULT_DEVICE_DETAILS.location.apartmentNr
            }
            city={
              device.deviceDetails?.location?.city
                ? device.deviceDetails.location.city
                : DEFAULT_DEVICE_DETAILS.location.city
            }
            zip={
              device.deviceDetails?.location?.zipCode
                ? device.deviceDetails.location.zipCode
                : DEFAULT_DEVICE_DETAILS.location.zipCode
            }
            state={
              device.deviceDetails?.location?.region
                ? device.deviceDetails.location.region
                : DEFAULT_DEVICE_DETAILS.location.region
            }
            country={
              device.deviceDetails?.location?.country
                ? device.deviceDetails.location.country
                : DEFAULT_DEVICE_DETAILS.location.country
            }
          />{" "}
        </div>
      </div>
    </Card>
  );
};
