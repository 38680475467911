import { useCliftContext } from "../hooks/useCliftContext";

export const isRoleForAction = (allowedRoles: string[]): boolean => {
  const { currentUser, cliftState } = useCliftContext();

  if (
    allowedRoles.some((role) =>
      currentUser?.clientPrincipal?.userRoles?.includes(
        role + "_" + cliftState.currentTenant?.id
      )
    )
  ) {
    return true;
  }
  return false;
};

export const hasRoleForTenant = (
  allowedRoles: string[],
  tenantId: number
): boolean => {
  const { currentUser } = useCliftContext();
  if (
    allowedRoles.some((role) =>
      currentUser?.clientPrincipal?.userRoles?.includes(role + "_" + tenantId)
    )
  ) {
    return true;
  }
  return false;
};
