import { useTranslation } from "react-i18next";
import { Panels } from "../context/clift-context/clift-context";
import { useCliftContext } from "../hooks/useCliftContext";
import { CliftReducerAction } from "../context/clift-context/clift-action-types";
import { ReactComponent as XIcon } from "../assets/X.svg";
import "./AlertsPanel.css";

export function AlertsPanel() {
  const { t } = useTranslation();

  const { cliftState, dispatchCliftState } = useCliftContext();
  if ((cliftState.visiblePanels & Panels.Alerts) == 0) return <></>;

  const alertAmount = cliftState.alerts.length;

  const removeAlerts = () =>
    dispatchCliftState({
      type: CliftReducerAction.RemoveAllAlerts,
    });

  const removeSingleAlert = (index: number) => {
    dispatchCliftState({
      type: CliftReducerAction.RemoveAlert,
      alertId: index,
    });
  };

  return (
    <div className="topPanel">
      <div className="topPanel-header">
        <div className="topPanel-header-title">
          {t("alerts", { ns: "alerts", amount: alertAmount })}
        </div>
        <div className="topPanel-header-buttons">
          {" "}
          <button
            className="topPanel-header-delete-button"
            disabled={alertAmount < 1}
            onClick={removeAlerts}
          >
            {t("delete_all", { ns: "translation" })}
          </button>
        </div>
      </div>
      <div className="topPanel-content">
        {alertAmount > 0 && (
          <div className="topPanel-items">
            {cliftState.alerts.map((alert, i) => (
              <div className="topPanel-item" key={i}>
                {alert}{" "}
                <button onClick={() => removeSingleAlert(i)}>
                  <XIcon />{" "}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
