import { Contact, ContactType } from "../../models/contact";
import { ReactComponent as CallIcon } from "../../assets/Call.svg";
import { useTranslation } from "react-i18next";
import { NoItems } from "../../components/NoItems";
import "./TenantDetailContacts.css";

export interface TenantDetailContactsProps {
  contacts: Contact[];
  editAllowed: boolean;
  onEditContacts: () => void;
}

export const TenantDetailContacts = ({
  contacts,
  editAllowed,
  onEditContacts,
}: TenantDetailContactsProps) => {
  const { t } = useTranslation();
  const emergencyNumber = contacts.find(
    (c) => c.contactType === ContactType.EMERGENCY
  );

  const supportNumbers = contacts.filter(
    (c) => c.contactType === ContactType.SUPPORT
  );

  return (
    <div className="tenant-detail-contacts">
      <div className="tenant-detail-contacts-item">
        <div className="tenant-detail-contacts-item-area">
          <div className="tenant-detail-contacts-item-content">
            <CallIcon />
            <h3>{t("emergency_number", { ns: "contact" })}</h3>
            <h4>
              {emergencyNumber?.phoneNr ??
                t("emergency_number_not_set", { ns: "contact" })}
            </h4>
          </div>
        </div>
        <div className="tenant-detail-contacts-item-line" />
      </div>

      <h2>{t("tenant_support_numbers_subtitle", { ns: "tenant" })}</h2>

      {supportNumbers.length > 0 &&
        supportNumbers.map((contact, i) => (
          <div key={i} className="tenant-detail-contacts-item">
            <div className="tenant-detail-contacts-item-area">
              <div className="tenant-detail-contacts-item-content">
                <CallIcon />
                <h3>{contact.displayName}</h3>
                <h4>{contact.phoneNr}</h4>
              </div>
            </div>
            <div className="tenant-detail-contacts-item-line" />
          </div>
        ))}

      {!supportNumbers.length && (
        <NoItems
          title={t("no_support_numbers_added", { ns: "tenant" })}
          subtitle={
            editAllowed
              ? t("no_support_numbers_added_hint", { ns: "tenant" })
              : ""
          }
          button={
            <button
              className="button"
              disabled={!editAllowed}
              type="button"
              onClick={onEditContacts}
            >
              {t("add_new_support_numbers_btn", { ns: "tenant" })}
            </button>
          }
        />
      )}
    </div>
  );
};
