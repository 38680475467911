import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import Card, { CardDetailTitled } from "../../../components/Card";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { isRoleForAction } from "../../../hooks/useRoles";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import {
  Device,
  DeviceSettings,
  DeviceUILanguage,
  SubscriptionState,
} from "../../../models/device";
import { LiftSettingsAllowedRoles } from "../../../models/role";
import { ToggleSwitch } from "../../../components/ToggleSwitch";
import { editDeviceSettings } from "../../../services/device-settings-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../../services/clift-api-errors";
import "./DeviceSettingsCard.css";

export interface DeviceSettingsCardProps {
  device: Device;
  setDevice: (device: Device) => void;
}

const DEFAULT_SETTINGS = {
  id: 0,
  subscriptionStartDate: new Date("2024-10-20").toUTCString(),
  subscriptionEndDate: new Date("2025-10-20").toUTCString(),
  subscriptionState: SubscriptionState.UNREGISTERED,
  deviceUiLanguage: DeviceUILanguage.ENGLISH,
  liftGuiTelemetryEnabled: true,
  liftEcuUpdateInterval: 1000,
  liftEcuTelemetryEnabled: true,
  batteryVoltageReadInterval: 1000,
  networkStateTelemetryEnabled: true,
  networkStateUpdateInterval: 1000,
  runtimeInfoUpdateInterval: 1000,
  runtimeInfoTelemetryEnabled: true,
  temperatureUpdateInterval: 1000,
  temperatureTelemetryEnabled: true,
};

export const DeviceSettingsCard = ({
  device,
  setDevice,
}: DeviceSettingsCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [settings, setSettings] = useState<DeviceSettings>(DEFAULT_SETTINGS);
  const { t } = useTranslation();

  useEffect(() => {
    setSettings(
      device.deviceSettings ? device.deviceSettings : DEFAULT_SETTINGS
    );
  }, [device.deviceSettings]);

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditDeviceSettings,
      tenantID: cliftState.currentTenant.id,
      device,
    });
  };

  const updateDeviceSettings = async (deviceSettings: DeviceSettings) => {
    if (!deviceSettings || !cliftState.currentTenant) return;
    const requestBody = { ...deviceSettings };

    return editDeviceSettings(
      cliftState.currentTenant.id,
      device.id,
      requestBody
    )
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_settings_update_success", {
            deviceId: device.id,
            ns: "alerts",
          }),
        });
        toast.success(t("changes_saved", { ns: "toasts" }));
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_settings_update_failed", {
            ns: "alerts",
            deviceId: device.id,
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("edit_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  const onValueChanged = (name: string, value: boolean) => {
    const updatedSettings = {
      ...settings,
      [name]: value,
    };
    setSettings(updatedSettings);
    updateDeviceSettings(updatedSettings);
    setDevice({ ...device, deviceSettings: updatedSettings });
  };

  return (
    <Card
      title={t("device_settings_title", { ns: "lift" })}
      subtitle={t("setting_mastered_by_admin", { ns: "lift" })}
      editAllowed={isRoleForAction(LiftSettingsAllowedRoles)}
      onEdit={handleEdit}
    >
      <CardDetailTitled
        title={t("lift_gui_telemetry_enabled", { ns: "lift" })}
        value={""}
        button={
          <ToggleSwitch
            on={settings.liftGuiTelemetryEnabled}
            text={true}
            onChange={(on) => onValueChanged("liftGuiTelemetryEnabled", on)}
          />
        }
      />

      <div className="device-settings-card-subtitle">
        {t("update_intervals", { ns: "lift" })}
      </div>

      <CardDetailTitled
        title={t("stairlift_state", { ns: "lift" })}
        value={
          settings.liftEcuTelemetryEnabled
            ? settings.liftEcuUpdateInterval?.toString()
            : ""
        }
        button={
          !settings.liftEcuTelemetryEnabled && (
            <ToggleSwitch
              on={settings.liftEcuTelemetryEnabled}
              text={true}
              onChange={(on) => onValueChanged("liftEcuTelemetryEnabled", on)}
            />
          )
        }
      />
      <CardDetailTitled
        title={t("network_state_update_interval", { ns: "lift" })}
        value={
          settings.networkStateTelemetryEnabled
            ? settings.networkStateUpdateInterval?.toString()
            : ""
        }
        button={
          !settings.networkStateTelemetryEnabled && (
            <ToggleSwitch
              on={settings.networkStateTelemetryEnabled}
              text={true}
              onChange={(on) =>
                onValueChanged("networkStateTelemetryEnabled", on)
              }
            />
          )
        }
      />
      <CardDetailTitled
        title={t("runtime_info_update_interval", { ns: "lift" })}
        value={
          settings.runtimeInfoTelemetryEnabled
            ? settings.runtimeInfoUpdateInterval?.toString()
            : ""
        }
        button={
          !settings.runtimeInfoTelemetryEnabled && (
            <ToggleSwitch
              on={settings.runtimeInfoTelemetryEnabled}
              text={true}
              onChange={(on) =>
                onValueChanged("runtimeInfoTelemetryEnabled", on)
              }
            />
          )
        }
      />
      <CardDetailTitled
        title={t("temperature_update_interval", { ns: "lift" })}
        value={
          settings.temperatureTelemetryEnabled
            ? settings.temperatureUpdateInterval?.toString()
            : ""
        }
        button={
          !settings.temperatureTelemetryEnabled && (
            <ToggleSwitch
              on={settings.temperatureTelemetryEnabled}
              text={true}
              onChange={(on) =>
                onValueChanged("temperatureTelemetryEnabled", on)
              }
            />
          )
        }
      />

      <CardDetailTitled
        title={t("battery_voltage_read_interval", { ns: "lift" })}
        value={settings?.batteryVoltageReadInterval?.toString()}
      />
    </Card>
  );
};
