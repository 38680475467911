import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import {
  TenantResponseFull,
  TenantAdmin,
  TenantFormValues,
  TenantType,
} from "../../models/tenant";
import { TenantFormAdminsPage } from "./TenantFormAdminsPage";
import { TenantFormDetailsPage } from "./TenantFormDetailsPage";
import { TenantFormContactsPage } from "./TenantFormContactsPage";
import { Contact, ContactType } from "../../models/contact";
import { emailRegex } from "../../app/constants";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import "./TenantForm.css";
import { isRoleForAction } from "../../hooks/useRoles";
import { EditTenantAllowedRoles } from "../../models/role";

export const enum TenantFormPages {
  TenantDetailsPage,
  TenantAdminPage,
  TenantContactPage,
}

const TENANT_DEFAULT_VALUES = {
  name: null,
  address: null,
  businessId: "",
  email: null,
  phoneNr: null,
  region: null,
  tenantType: TenantType.COUNTRY,
  parentTenantId: null,
  contacts: [],
  admins: [{ email: "" }],
};

const DEFAULT_EMERGENCY_CONTACT = {
  displayName: "",
  phoneNr: "112",
  contactType: ContactType.EMERGENCY,
  firstName: "",
  lastName: "",
};

const DEFAULT_SUPPORT_CONTACT = {
  displayName: "Support",
  phoneNr: "",
  contactType: ContactType.SUPPORT,
  firstName: "",
  lastName: "",
};

export interface TenantFormProps {
  onSubmit: (
    tenant: Omit<TenantResponseFull, "id" | "tenant">,
    admins: TenantAdmin[],
    contacts: Contact[]
  ) => Promise<void>;
  onClose: () => void;
  onSwitchPage: (pageIndex: number) => void;
  pageIndex: number;
  initialValues?: TenantFormValues;
  editingTenant?: TenantResponseFull | undefined;
  showAdminsPage?: boolean;
  setBottomButtonsProps: (buttonsProps: SideModalButtonBarProps) => void;
}

export const TenantForm = ({
  onSubmit,
  onClose,
  onSwitchPage,
  pageIndex,
  showAdminsPage = true,
  initialValues = TENANT_DEFAULT_VALUES,
  editingTenant,
  setBottomButtonsProps,
}: TenantFormProps) => {
  const [tenant, setTenant] = useState(initialValues);
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const [admins, setAdmins] = useState<string[]>([""]);
  const [supportContacts, setSupportNumbers] = useState<Contact[]>([
    DEFAULT_SUPPORT_CONTACT,
  ]);
  const [emergencyContact, setEmergencyContact] = useState<Contact>(
    DEFAULT_EMERGENCY_CONTACT
  );

  const handleFormValid = () => {
    switch (pageIndex) {
      case TenantFormPages.TenantDetailsPage:
        if (tenant.name && tenant.businessId && tenant.phoneNr) {
          return true;
        }
        return false;
      case TenantFormPages.TenantAdminPage:
        // There's always an admins array where the first element is '' so we need to check
        // the required field in a different way
        if (admins[0] && admins[0].length > 0) {
          return emailRegex.test(admins[0]);
        }
        return false;
      case TenantFormPages.TenantContactPage:
        if (
          emergencyContact.phoneNr &&
          supportContacts[0] &&
          supportContacts[0].displayName &&
          supportContacts[0].phoneNr
        ) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const onHandleFieldChanged = (name: string, value: string | number) => {
    setChanged(true);
    setTenant({
      ...tenant,
      [name]: value,
    });
  };

  const onHandleAdminChanged = (index: number, value: string) => {
    setChanged(true);
    const newValue = admins.map((val, i) => {
      if (i === index) return value as string;
      return val;
    });
    setAdmins(newValue);
  };

  const handleDeleteAdmin = (index: number) => {
    setChanged(true);
    const newList = admins.filter((_, i) => {
      if (i === index) return false;
      return true;
    });
    setAdmins(newList);
  };

  const handleAddAdmin = () => {
    setAdmins(admins.concat(""));
  };

  const handleAddNewContact = () => {
    setChanged(true);
    setSupportNumbers(supportContacts.concat(DEFAULT_SUPPORT_CONTACT));
  };

  const handleDeleteContact = (index: number) => {
    setChanged(true);
    const newList = supportContacts.filter((_, i) => {
      if (i === index) return false;
      return true;
    });
    setSupportNumbers(newList);
  };

  const onHandleContactChanged = (
    name: string,
    index: number,
    value: string
  ) => {
    setChanged(true);
    const newValue = supportContacts.map((val, i) => {
      if (i === index) {
        return {
          ...val,
          [name]: value,
        };
      }
      return val;
    });
    setSupportNumbers(newValue);
  };

  const onHandleEmergencyNumberChanged = (value: string) => {
    setChanged(true);
    setEmergencyContact({
      ...emergencyContact,
      phoneNr: value,
    });
  };

  const onCancel = () => {
    if (hasChanged) {
      setCanceled(true);
    } else {
      onClose();
    }
  };

  const onNext = () => {
    if (form.current?.reportValidity()) {
      if (pageIndex == 0) {
        onSwitchPage(showAdminsPage ? 1 : 2);
      } else {
        onSwitchPage(++pageIndex);
      }
    }
  };

  const onBack = () => {
    if (pageIndex == 2) {
      onSwitchPage(showAdminsPage ? 1 : 0);
    } else {
      onSwitchPage(--pageIndex);
    }
  };

  const onSave = () => {
    onSubmit(
      tenant,
      admins.map((email) => ({ email })),
      supportContacts.concat([emergencyContact])
    );
  };

  const bottomButtonsProps = useMemo(() => {
    return {
      showSave:
        pageIndex === TenantFormPages.TenantContactPage &&
        isRoleForAction(EditTenantAllowedRoles),
      ...(pageIndex !== TenantFormPages.TenantDetailsPage && { onBack }),
      ...(pageIndex !== TenantFormPages.TenantContactPage && { onNext }),
      onCancel,
      ...(pageIndex === TenantFormPages.TenantContactPage
        ? { saveDisabled: !handleFormValid() }
        : { nextDisabled: !handleFormValid() }),
      onSave: onSave,
    };
  }, [pageIndex, handleFormValid, onCancel, onBack, onNext, onSave]);

  useEffect(() => {
    setBottomButtonsProps(bottomButtonsProps);
  }, [tenant, admins, pageIndex, supportContacts, emergencyContact]);

  useEffect(() => {
    setAdmins(editingTenant?.admins?.map((admin) => admin.email) ?? [""]);

    const contacts = editingTenant?.contacts?.filter(
      (c) => c.contactType === ContactType.SUPPORT
    );

    setSupportNumbers(contacts?.length ? contacts : [DEFAULT_SUPPORT_CONTACT]);
    setEmergencyContact(
      editingTenant?.contacts?.find(
        (c) => c.contactType === ContactType.EMERGENCY
      ) ?? DEFAULT_EMERGENCY_CONTACT
    );
  }, [editingTenant]);

  return (
    <div className="tenant-form">
      <form ref={form}>
        {pageIndex === TenantFormPages.TenantDetailsPage && (
          <TenantFormDetailsPage
            tenant={tenant}
            originalTenant={editingTenant}
            onFieldChanged={onHandleFieldChanged}
          />
        )}

        {pageIndex === TenantFormPages.TenantAdminPage && (
          <TenantFormAdminsPage
            tenant={tenant}
            originalTenant={editingTenant}
            admins={admins}
            onAdminChanged={onHandleAdminChanged}
            onAddAdmin={handleAddAdmin}
            onDeleteAdmin={handleDeleteAdmin}
          />
        )}
        {pageIndex === TenantFormPages.TenantContactPage && (
          <TenantFormContactsPage
            supportContacts={supportContacts}
            emergencyContact={emergencyContact}
            onAddNewContact={handleAddNewContact}
            onDeleteContact={handleDeleteContact}
            onContactChanged={onHandleContactChanged}
            onEmergencyNumberChanged={onHandleEmergencyNumberChanged}
          />
        )}
      </form>

      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
