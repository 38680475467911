import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { Device } from "../../../models/device";
import { getErrorCode } from "../../../services/clift-api-errors";
import { fetchDeviceState } from "../../../services/devices-api";
import { ReactComponent as RefreshIcon } from "../../../assets/Refresh.svg";
// import { ReactComponent as BatteryLowIcon } from "../../../assets/Battery-low.svg";
import { ReactComponent as BatteryHighIcon } from "../../../assets/Battery-high.svg";
import { ReactComponent as RedFrameIcon } from "../../../assets/RedCircleFrame.svg";
import { ReactComponent as GreenFrameIcon } from "../../../assets/GreenCircleFrame.svg";
import { ReactComponent as OrangeFrameIcon } from "../../../assets/OrangeCircleFrame.svg";
import { ReactComponent as ConnectedIcon } from "../../../assets/Connected.svg";
import { ReactComponent as DisconnectedIcon } from "../../../assets/Disconnected.svg";
import { ReactComponent as DeviceErrIcon } from "../../../assets/DeviceErr.svg";
import { ReactComponent as DeviceOkIcon } from "../../../assets/DeviceOk.svg";
import { ReactComponent as DeviceAlertIcon } from "../../../assets/DeviceAlert.svg";
import "./DeviceStateInfo.css";

enum HealthStatus {
  ALERT,
  GOOD,
  ERROR,
}

export interface DeviceStateInfoProps {
  device: Device;
}

const DeviceStateInfo = ({ device }: DeviceStateInfoProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [healthStatus, setHealthStatus] = useState<HealthStatus>(
    HealthStatus.ALERT
  );
  const { t } = useTranslation();

  const getHealthStatus = (faultCode: string | null) => {
    if (faultCode == null) return HealthStatus.ALERT;

    // TODO update codes
    return ["E0000", "null"].includes(faultCode)
      ? HealthStatus.GOOD
      : HealthStatus.ERROR;
  };

  const getHealthStateIcon = (
    healthStatus: HealthStatus,
    frameIcon: boolean
  ) => {
    if (healthStatus === HealthStatus.GOOD) {
      return frameIcon ? <GreenFrameIcon /> : <DeviceOkIcon />;
    } else if (healthStatus === HealthStatus.ERROR) {
      return frameIcon ? <RedFrameIcon /> : <DeviceErrIcon />;
    } else {
      return frameIcon ? <OrangeFrameIcon /> : <DeviceAlertIcon />;
    }
  };

  const getHealthStateText = (healthStatus: HealthStatus) => {
    switch (healthStatus) {
      case HealthStatus.GOOD:
        return t("health_good", { ns: "lift" });
      case HealthStatus.ERROR:
        return t("fault", { ns: "lift" });
      default:
        return t("health_alert", { ns: "lift" });
    }
  };

  const updateDeviceState = (
    tenantId: number | undefined,
    deviceId: number | undefined
  ) => {
    if (!tenantId || !deviceId) {
      return;
    }
    setLoading(true);
    fetchDeviceState(tenantId, deviceId)
      .then((state) => {
        setLoading(false);
        setHealthStatus(getHealthStatus(state.faultCode));
      })
      .catch((err) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    if (device.id !== undefined && cliftState.currentTenant?.id) {
      updateDeviceState(cliftState.currentTenant?.id, device.id);
    }
  }, [device.id, cliftState.currentTenant]);

  return (
    <div className="device-state-info">
      {loading && <progress></progress>}
      {!loading && (
        <>
          <div className="device-state-info-circle-frame">
            <GreenFrameIcon />
            <div className="device-state-info-circle-content">
              <BatteryHighIcon />
              <div className="device-stateinfo-circle-data">{"100%"}</div>
            </div>
          </div>

          <div className="device-state-info-circle-frame">
            {device.connected ? <GreenFrameIcon /> : <RedFrameIcon />}
            <div className="device-state-info-circle-content">
              {device.connected ? <ConnectedIcon /> : <DisconnectedIcon />}

              <div className="device-stateinfo-circle-data">
                {device.connected
                  ? t("connected", { ns: "lift" })
                  : t("disconnected", { ns: "lift" })}
              </div>
            </div>
          </div>

          <div className="device-state-info-circle-frame">
            {getHealthStateIcon(healthStatus, true)}
            <div className="device-state-info-circle-content">
              {getHealthStateIcon(healthStatus, false)}
              <div className="device-stateinfo-circle-data">
                {getHealthStateText(healthStatus)}
              </div>
            </div>
          </div>

          <div>
            <button
              title={t("refresh")}
              className="device-state-info-refresh-button"
              onClick={() =>
                updateDeviceState(cliftState.currentTenant?.id, device.id)
              }
              disabled={loading}
            >
              <RefreshIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceStateInfo;
