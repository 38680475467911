import "./ToggleSwitch.css";

export interface ToggleSwitchProps {
  label?: string;
  on: boolean;
  text?: boolean;
  onChange?: (on: boolean) => void;
}

export const ToggleSwitch = ({
  label,
  on,
  text,
  onChange,
}: ToggleSwitchProps) => {
  const toggleOnStyles = !text ? "toggle-switch-on" : "toggle-switch-on-card";
  const toggleOffStyles = !text
    ? "toggle-switch-off"
    : "toggle-switch-off-card";

  return (
    <>
      <div className="toggle-switch-container">
        {label && <span className="toggle-switch-label">{label}</span>}
        <button
          title="toggle"
          type="button"
          disabled={!onChange}
          className={`toggle-switch-btn ${on ? toggleOnStyles : toggleOffStyles}`}
          onClick={() => {
            onChange?.(!on);
          }}
        >
          <div
            className={`${text ? "toggle-switch-text" : ""} ${on ? "on" : "off"}`}
          >
            {text && (on ? "ON" : "OFF")}
          </div>
          <div className="toggle-switch-thumb" />
        </button>
      </div>
    </>
  );
};
