import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { CustomerFullResponse } from "../../models/customer";
import { AddLiftsForm } from "../lifts/AddLiftsForm";
import { addDeviceToCustomer } from "../../services/customers-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { useState } from "react";
import { DeviceRegistrationItem } from "models/device";
import { toast } from "react-toast";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";

export interface AddCustomerLiftsProps {
  editingCustomer: CustomerFullResponse;
}

export const AddCustomerLifts = ({
  editingCustomer,
}: AddCustomerLiftsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [bottomButtonsProps, setBottomButtonsProps] =
    useState<SideModalButtonBarProps>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddLifts = (devices: DeviceRegistrationItem[]) => {
    addDeviceToCustomer(editingCustomer.tenantId, editingCustomer.id, devices)
      .then(() => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_add_success", {
            ns: "alerts",
            customerId: editingCustomer.id,
          }),
        });
        toast.success(
          t("add_device_success", {
            ns: "toasts",
          })
        );
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_add_failed", {
            ns: "alerts",
            customerId: editingCustomer.id,
            code: getErrorCode(err),
          }),
        });
        toast.error(t("error") + t("device_not_found", { ns: "lift" }));
      });
  };

  return (
    <SideModal
      title={t("add_lifts_title", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      onClose={closeSidePanel}
    >
      <AddLiftsForm
        error={error}
        initialDevices={editingCustomer.devices as DeviceRegistrationItem[]}
        onSubmit={onAddLifts}
        onClose={closeSidePanel}
        omitNickName={false}
        setBottomButtonsProps={setBottomButtonsProps}
      />
    </SideModal>
  );
};
