import {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  forwardRef,
} from "react";
import "./FormField.css";
import { useTranslation } from "react-i18next";
import { useTooltip } from "../hooks/useTooltip";

export interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  name: string;
  hintText?: string;
  className?: string;
  optional?: boolean;
  extensionComponent?: ReactNode;
  onValueChanged?: (name: string, value: string | number) => void;
}

export const invalidFormField = "invalid-input";

const FormField = forwardRef<
  HTMLInputElement,
  PropsWithChildren<FormFieldProps>
>((props, ref) => {
  const {
    title,
    name,
    hintText,
    className,
    optional,
    extensionComponent,
    onValueChanged,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { tooltipVisible, tooltipPosition, elementRef } = useTooltip();

  const handleInputChange = function (event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    onValueChanged?.(name, value);
  };

  let ariaAutoComplete = props["aria-autocomplete"];
  if (!ariaAutoComplete) {
    ariaAutoComplete = props.autoComplete === "off" ? "none" : undefined;
  }

  let list = props.list;
  if (!list) {
    list = props.autoComplete === "off" ? "autocompleteOff" : undefined;
  }

  return (
    <div className={className?.length ? className + " formfield" : "formfield"}>
      <label
        className={`${props.disabled ? "label-disabled" : ""}`}
        htmlFor={name}
      >
        {title}
        {optional && (
          <div className="formfield-optional">
            {t("optional_field", { ns: "translation" })}
          </div>
        )}
      </label>
      <div className="formfield-inputs">
        <input
          id={name}
          name={name}
          ref={(node) => {
            elementRef.current = node;
            if (ref) {
              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }
          }}
          list={list}
          aria-autocomplete={ariaAutoComplete}
          type={props.type ?? "text"}
          onChange={handleInputChange}
          {...rest}
        />
        {extensionComponent && (
          <div className="formfield-extension">{extensionComponent} </div>
        )}
      </div>
      {tooltipVisible && (
        <div
          className="tooltip"
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          {props.value}
        </div>
      )}
      {hintText && <div className="formfield-hint-text">{hintText}</div>}
    </div>
  );
});

FormField.displayName = "FormField";
export default FormField;
