import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PaginatedList from "../../components/PaginatedList";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useCsvExport } from "../../hooks/useCsvExport";
import { DeviceSortableColumns } from "../../models/device";
import { DeviceListItem } from "../../models/device-list-item";
import { Direction, SearchParams, Sort } from "../../models/search";
import { getErrorCode } from "../../services/clift-api-errors";
import { fetchAllTenantDevices } from "../../services/devices-api";
import { ReactComponent as DownIcon } from "../../assets/ArrowDown.svg";
import { ReactComponent as UpIcon } from "../../assets/ArrowUp.svg";
import "./LiftList.css";
import { isRoleForAction } from "../../hooks/useRoles";
import { LiftViewAllowedRoles } from "../../models/role";
import { Link } from "react-router-dom";

export const LiftSimpleList = () => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<DeviceListItem>();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [devices, setDevices] = useState<DeviceListItem[]>([]);
  const [totalLifts, setTotalLifts] = useState<number>(0);
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: DeviceSortableColumns.MODEL,
  });
  const handleSort = (column: DeviceSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const getSortableColumnHeader = (
    column: DeviceSortableColumns,
    title: string
  ) => {
    const activeColumn = sort.column === column;
    return (
      <div className="sortable-header" onClick={() => handleSort(column)}>
        {title}
        <div className="sortable-header-arrows">
          <UpIcon
            className={`${activeColumn && sort.direction === Direction.ASC ? "active-arrow" : ""} `}
          />
          <DownIcon
            className={`${activeColumn && sort.direction === Direction.DESC ? "active-arrow" : ""} `}
          />
        </div>
      </div>
    );
  };

  const columns: ColumnDef<DeviceListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("serialNumber", {
        header: () =>
          getSortableColumnHeader(
            DeviceSortableColumns.SERIAL_NUMBER,
            t("serial_number", { ns: "lift" })
          ),
        cell: (info) => {
          if (isRoleForAction(LiftViewAllowedRoles)) {
            return (
              <Link
                to={
                  info.row.original.id.toString() +
                  "?tenantId=" +
                  info.row.original.tenantId
                }
                onClick={() => {
                  dispatchCliftState({
                    type: CliftReducerAction.SetFromOriginalPage,
                    fromOriginalPage: "/lifts",
                  });
                }}
              >
                {info.row.original.serialNumber}
              </Link>
            );
          }
          return info.row.original.serialNumber;
        },
      }),
      columnHelper.accessor("model", {
        header: () =>
          getSortableColumnHeader(
            DeviceSortableColumns.MODEL,
            t("model", { ns: "lift" })
          ),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("connected", {
        header: () =>
          getSortableColumnHeader(
            DeviceSortableColumns.CONNECTED,
            t("connected", { ns: "lift" })
          ),
        cell: (info) =>
          info.getValue()
            ? t("connected", { ns: "lift" })
            : t("disconnected", { ns: "lift" }),
      }),
    ],
    [i18n.language, sort]
  );

  const fetchLifts = (searchParams: SearchParams) => {
    return fetchAllTenantDevices(searchParams, cliftState.currentTenant?.id)
      .then((res) => {
        setTotalLifts(res.total);
        setDevices(res.content);
      })
      .catch((err) => {
        setDevices([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  const handleExport = useCsvExport();

  return (
    <PaginatedList<DeviceListItem>
      sort={sort}
      columns={columns}
      data={devices}
      fetchData={fetchLifts}
      totalElements={totalLifts}
      onExport={() =>
        handleExport({
          tableName: "devices",
          tenantId: cliftState.currentTenant?.id ?? -1,
        })
      }
    />
  );
};
