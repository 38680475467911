import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { Device } from "../../../models/device";
import { fetchDevice } from "../../../services/devices-api";
import { getErrorCode } from "../../../services/clift-api-errors";
import { CardContainer } from "../../../components/Card";
import Page from "../../../components/Page";
import { SimSettingsCard } from "./SimSettingsCard";
import { SipSettingsCard } from "./SipSettingsCard";
import { ContactInfoCard } from "./ContactInfoCard";
import { DeviceInfoCard } from "./DeviceInfoCard";
import { DeviceSettingsCard } from "./DeviceSettingsCard";
import { DeviceTechInfoCard } from "./DeviceTechInfoCard";
import { CarersCard } from "./carers/CarersCard";
import { NetworkInfoCard } from "./NetworkInfoCard";
import Header from "../../../components/Header";
import LiftStateCard from "./LiftStateCard";
import { hasRoleForTenant } from "../../../hooks/useRoles";
import { ViewLiftCustomerDataRoles } from "../../../models/role";

export const LiftDetails = () => {
  const params = useParams();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [device, setDevice] = useState<Device>();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getTenantId = (): number => {
    const tenantIdFromUrl = queryParams.get("tenantId");
    return Number(tenantIdFromUrl) || cliftState?.currentTenant?.id || 0;
  };

  const getDeviceId = () => {
    const deviceID =
      params.deviceID !== undefined ? parseInt(params.deviceID) : undefined;
    return deviceID !== undefined && !isNaN(deviceID) ? deviceID : undefined;
  };

  useEffect(() => {
    const deviceId = getDeviceId();
    const tenantId = getTenantId();
    if (deviceId !== undefined && !cliftState.sidepanelContent && !!tenantId) {
      fetchDevice(tenantId, deviceId)
        .then((dev) => {
          setDevice(dev);
        })
        .catch((err) => {
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
          setError(true);
        });
    }
  }, [params.deviceID, cliftState.sidepanelContent, cliftState.currentTenant]);

  if (error) {
    return <div></div>;
  } else if (!device) {
    return <progress></progress>;
  }

  return (
    <Page title={device.model} fromOriginalPage={cliftState.fromOriginalPage!}>
      {device && getTenantId() && (
        <CardContainer>
          <Header title={t("stairlift_device_information", { ns: "lift" })} />
          <DeviceInfoCard device={device} />
          <DeviceTechInfoCard
            tenantId={getTenantId()}
            deviceId={getDeviceId()}
          />
          <NetworkInfoCard
            tenantId={getTenantId()}
            deviceId={getDeviceId()}
            deviceConnected={device.connected}
          />

          <Header title={t("stairlift_settings", { ns: "lift" })} />
          {hasRoleForTenant(ViewLiftCustomerDataRoles, getTenantId()) && (
            <SipSettingsCard device={device} />
          )}
          <DeviceSettingsCard device={device} setDevice={setDevice} />
          {hasRoleForTenant(ViewLiftCustomerDataRoles, getTenantId()) && (
            <SimSettingsCard device={device} />
          )}
          <Header title={t("stairlift_contacts_and_viewers", { ns: "lift" })} />
          {device.contactInfo &&
            hasRoleForTenant(ViewLiftCustomerDataRoles, getTenantId()) && (
              <ContactInfoCard
                contactInfo={device.contactInfo}
                deviceId={device.id}
              />
            )}
          <CarersCard carers={device.carers} deviceId={device.id} />

          <Header title={t("stairlift_usage_details", { ns: "lift" })} />

          <LiftStateCard deviceId={device.id} />
        </CardContainer>
      )}
    </Page>
  );
};
