import "./Header.css";

export interface HeaderProps {
  title: string;
}

const Header = ({ title }: HeaderProps) => {
  return (
    <div className="header-content">
      <h3 className="header-title">{title}</h3>
      <div className="header-line"></div>
    </div>
  );
};

export default Header;
