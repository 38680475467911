import { useTranslation } from "react-i18next";
import { Panels } from "../context/clift-context/clift-context";
import { useCliftContext } from "../hooks/useCliftContext";
import "./UserPanel.css";

export function UserPanel() {
  const { t } = useTranslation();
  const { currentUser } = useCliftContext();

  const { cliftState } = useCliftContext();
  if ((cliftState.visiblePanels & Panels.User) == 0) return <></>;
  return (
    <div className="userpanel">
      {currentUser?.clientPrincipal && (
        <div>
          {currentUser?.clientPrincipal.userDetails}
          <br />
          <a href="/.auth/logout">{t("sign_out", { ns: "profile" })}</a>
        </div>
      )}
    </div>
  );
}
