import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import { InviteFormValues } from "../../models/user";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import {
  StannahRole,
  RoleFormValues,
  EmployeeListEditAllowedRoles,
} from "../../models/role";
import "./Employees.css";
import { RolesForm } from "./RolesForm";
import { emailRegex } from "../../app/constants";
import { isRoleForAction } from "../../hooks/useRoles";
import { useCliftContext } from "../../hooks/useCliftContext";
import { ReactComponent as BackspaceIcon } from "../../assets/Backspace.svg";

export const enum CreateEmployeePages {
  EmployeePage,
  EmployeeRolesPage,
}

export interface InviteEmployeesFormProps {
  onSubmit: (emails: string[], roles: RoleFormValues[]) => Promise<void>;
  onClose: () => void;
  initialValues?: InviteFormValues;
  setBottomButtonsProps: (buttonsProps: SideModalButtonBarProps) => void;
}

export const InviteEmployeesForm = ({
  onSubmit,
  onClose,
  initialValues = {
    emails: [],
    roles: [],
  },
  setBottomButtonsProps,
}: InviteEmployeesFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState(initialValues?.emails || []);
  const [roles, setRoles] = useState<RoleFormValues[]>(
    initialValues?.roles || []
  );

  const { cliftState } = useCliftContext();

  useEffect(() => {
    setRoles([
      ...(roles || []),
      {
        tenantId: cliftState.currentTenant?.id,
        role: undefined,
      },
    ]);
  }, []);

  const employeeForm = useRef<HTMLFormElement>(null);

  const onHandleFieldChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setEmailInput(e.target.value.toString());
  };

  const checkForEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      e.preventDefault();
      addEmailFromInput();
    }
  };

  const addEmailFromInput = () => {
    if (!emailRegex.test(emailInput)) {
      return;
    }
    setChanged(true);
    setEmails([...emails, emailInput]);
    setEmailInput("");
  };

  const onHandleRoleChanged = (newRole: StannahRole, index: number) => {
    setChanged(true);
    const newRoles = roles.map((employeeRole, i) => {
      if (i === index) {
        return {
          ...employeeRole,
          role: newRole,
        };
      }
      return employeeRole;
    });
    setRoles(newRoles);
  };

  const onCancel = () => {
    if (hasChanged) {
      setCanceled(true);
    } else {
      onClose();
    }
  };

  const onSave = () => {
    addEmailFromInput();
    onSubmit(emails, roles);
  };

  const hasInvalidRole = roles.some((role) => role.role === undefined);

  const saveDisabled =
    roles.length == 0 || emails.length == 0 || hasInvalidRole;

  const saveButtonText = () => {
    if (saveDisabled) {
      return t("invite_employees", { ns: "employee", amount: "" });
    } else {
      return emails.length === 1
        ? t("invite_employee", { ns: "employee" })
        : t("invite_employees", { ns: "employee", amount: emails.length });
    }
  };

  const bottomButtonsProps = {
    onCancel: onCancel,
    onSave: onSave,
    showSave: true,
    saveDisabled: saveDisabled,
    saveButtonText: saveButtonText(),
  };

  useEffect(() => {
    setBottomButtonsProps(bottomButtonsProps);
  }, [emails, roles]);

  return (
    <div className="employee-form">
      <form ref={employeeForm}>
        <div className="employee-form-role-page">
          <div className="employee-form-creation-row">
            <div>
              <h3>{t("employee_info", { ns: "employee" })}</h3>
              <p>{t("employee_info_hint", { ns: "employee" })}</p>
              <h4>
                <b>{t("employee_info_note", { ns: "employee" })}</b>
              </h4>
            </div>
          </div>

          <div className="employee-form-creation-row">
            <div className="employee-form-email">
              <div className="employee-form-email-label">
                {t("email", { ns: "customer" })}
              </div>
              <div className="employee-form-email-input-container">
                {(emails || []).map((email, i) => (
                  <Chip
                    key={"email_" + i}
                    color="default"
                    label={email}
                    onDelete={() => setEmails(emails.toSpliced(i, 1))}
                  />
                ))}
                <input
                  className="employee-form-email-input"
                  type="text"
                  value={emailInput ?? ""}
                  placeholder={
                    emails.length < 1
                      ? t("enter_employee_email", { ns: "employee" })
                      : ""
                  }
                  onChange={onHandleFieldChanged}
                  onKeyDown={checkForEnter}
                  onBlur={addEmailFromInput}
                />
              </div>
              <div className="employee-form-email-input-hint">
                {t("separate_email_by_enter", { ns: "employee" })}
              </div>
            </div>

            <button
              className={`button employee-form-delete-emails-button ${emails.length <= 1 && "employee-form-delete-emails-button-invisilble"}`}
              type="button"
              onClick={() => {
                setEmails([]);
              }}
            >
              <BackspaceIcon />
            </button>
          </div>
          <div>
            <RolesForm
              handleRoleChanged={onHandleRoleChanged}
              setChanged={setChanged}
              setRoles={setRoles}
              roles={roles}
              hasEditRights={isRoleForAction(EmployeeListEditAllowedRoles)}
            />
          </div>

          <div className="employee-form-errors"></div>
          <div className="employee-form-footer">
            {t("employee_receive_email", { ns: "employee" })}
          </div>
        </div>
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
