import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card, {
  CardAddressInfo,
  CardContainer,
  CardDetail,
  CardGroup,
  CardType,
} from "../../components/Card";
import Page from "../../components/Page";
import { NoItems } from "../../components/NoItems";
import { CustomerFullResponse } from "../../models/customer";
import { ContactCommonResponse } from "../../models/contact";
import { fetchCustomer } from "../../services/customers-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { CustomerDetailDevices } from "./CustomerDetailDevices";
import { CreateCustomerPages } from "./CustomerForm";
import { ReactComponent as ProfileIcon } from "../../assets/Profile.svg";
import { ReactComponent as MailIcon } from "../../assets/Mail.svg";
import { ReactComponent as PhoneIcon } from "../../assets/Phone.svg";
import "./CustomerDetail.css";
import {
  CustomerContactsAllowedRoles,
  ViewCustomerAllowedRoles,
  ViewCustomerLiftsAllowedRoles,
  EditCustomerLiftsAllowedRoles,
} from "../../models/role";
import { EditCustomer } from "./EditCustomer";
import { AddCustomerLifts } from "./AddCustomerLifts";

export const CustomerDetail = () => {
  const isViewCustomerAllowed: boolean = isRoleForAction(
    ViewCustomerAllowedRoles
  );
  const isCustomerContactsAllowed: boolean = isRoleForAction(
    CustomerContactsAllowedRoles
  );
  const isViewCustomersLiftsAllowed: boolean = isRoleForAction(
    ViewCustomerLiftsAllowedRoles
  );
  const isEditCustomersLiftsAllowed: boolean = isRoleForAction(
    EditCustomerLiftsAllowedRoles
  );
  const { cliftState, dispatchCliftState } = useCliftContext();
  const navigate = useNavigate();

  const handeEditCustomerInfo = () => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditCustomer
          editingCustomer={customer}
          page={CreateCustomerPages.CustomerPage}
        />
      ),
    });
  };
  const handleEditContacts = () => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditCustomer
          editingCustomer={customer}
          page={CreateCustomerPages.CustomerContactsPage}
        />
      ),
    });
  };
  const handleEditLifts = () => {
    if (customer) {
      dispatchCliftState({
        type: CliftReducerAction.SetSidePanelContent,
        content: <AddCustomerLifts editingCustomer={customer} />,
      });
    }
  };

  const params = useParams();
  const [customer, setCustomer] = useState<CustomerFullResponse>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getCustomerId = () => {
    const customerID =
      params.customerID !== undefined ? parseInt(params.customerID) : undefined;
    return customerID !== undefined && !isNaN(customerID)
      ? customerID
      : undefined;
  };

  const getTitle = () => {
    if (customer) {
      return customer.organization
        ? customer.organization
        : `${customer.firstName} ${customer.lastName}`;
    }
    return "";
  };

  const getContactDetails = (contact: ContactCommonResponse) => {
    return (
      <>
        <div className="customer-detail-contact-row">
          <div>{contact.displayName}</div>
          <div>{contact.phoneNr}</div>
        </div>
      </>
    );
  };

  const handleLiftSelected = (index: number) => {
    const device = customer?.devices?.[index];
    if (device) {
      dispatchCliftState({
        type: CliftReducerAction.SetFromOriginalPage,
        fromOriginalPage: `/customers/${customer.id}`,
      });
      navigate(`/lifts/${device.id}`);
    }
  };

  useEffect(() => {
    if (!cliftState.currentTenant) {
      return;
    }
    const customerID = getCustomerId();
    if (customerID !== undefined && !cliftState.sidepanelContent) {
      setLoading(true);
      fetchCustomer(cliftState.currentTenant!.id, customerID)
        .then((res) => {
          setLoading(false);
          setCustomer(res);
        })
        .catch((err) => {
          setLoading(false);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [
    params.customerID,
    cliftState.sidepanelContent,
    cliftState.currentTenant,
  ]);

  if (loading) {
    return <progress></progress>;
  }
  return (
    <Page
      title={getTitle()}
      subtitle={`${t("id", { ns: "translation" })} - ${customer?.id}${customer && customer.vat ? `, ${t("vat", { ns: "customer" })}: ${customer.vat}` : ""}`}
      fromOriginalPage={cliftState.fromOriginalPage!}
    >
      {customer && (
        <CardContainer>
          {isViewCustomerAllowed && (
            <Card
              title={t("customer_information", { ns: "customer" })}
              subtitle={t("customer_information_subtitle", { ns: "customer" })}
              onEdit={handeEditCustomerInfo}
            >
              <CardGroup title={t("lift_owner", { ns: "customer" })}>
                <CardDetail
                  icon={<ProfileIcon />}
                  value={`${customer?.firstName} ${customer?.lastName}`}
                />
                <CardDetail icon={<MailIcon />} value={customer?.email} />
                <CardDetail icon={<PhoneIcon />} value={customer.phoneNr} />
                <CardAddressInfo
                  street={customer.address}
                  city={customer.city}
                  zip={customer.zipCode}
                  state={customer.region}
                  country={customer.country}
                  useHeader={false}
                ></CardAddressInfo>
              </CardGroup>
              <CardGroup title={t("account_owner", { ns: "customer" })}>
                <CardDetail
                  icon={<ProfileIcon />}
                  value={`${customer?.accountOwner?.firstName} ${customer?.accountOwner?.lastName}`}
                />
                <CardDetail
                  icon={<MailIcon />}
                  value={customer?.accountOwner?.email}
                />
                <CardDetail
                  icon={<PhoneIcon />}
                  value={customer?.accountOwner?.phoneNr}
                />
              </CardGroup>
            </Card>
          )}
          {isCustomerContactsAllowed && (
            <Card
              title={t("customer_contacts", { ns: "customer" })}
              onEdit={handleEditContacts}
            >
              {customer?.contacts &&
                customer?.contacts.map((contact, i) => (
                  <CardDetail
                    key={i}
                    icon={<ProfileIcon />}
                    value={getContactDetails(contact)}
                  />
                ))}
            </Card>
          )}
          {isViewCustomersLiftsAllowed && (
            <Card
              title={t("customer_lifts", { ns: "customer" })}
              cardType={CardType.DOUBLE}
              editAllowed={isEditCustomersLiftsAllowed}
              onEdit={handleEditLifts}
            >
              {customer.devices !== null && customer.devices.length > 0 ? (
                <CustomerDetailDevices
                  devices={customer.devices ?? []}
                  onItemSelected={handleLiftSelected}
                />
              ) : (
                <NoItems
                  title={t("no_lifts_added", { ns: "customer" })}
                  subtitle={t("no_lifts_added_hint", { ns: "customer" })}
                  button={
                    <button
                      className="button"
                      type="button"
                      onClick={handleEditLifts}
                    >
                      {t("add_new_lift_btn", { ns: "customer" })}
                    </button>
                  }
                />
              )}
            </Card>
          )}
        </CardContainer>
      )}
    </Page>
  );
};
