import FormField from "../../components/FormField";
import { TenantResponseFull } from "../../models/tenant";
import { useTranslation } from "react-i18next";
import "./TenantFormDetailsPage.css";
import PhoneNumberField from "../../components/PhoneNumberField";

export interface TenantFormDetailsPageProps {
  originalTenant?: TenantResponseFull | undefined;
  tenant: Omit<TenantResponseFull, "id" | "tenant">;
  onFieldChanged: (name: string, value: string | number) => void;
}

export const TenantFormDetailsPage = ({
  tenant,
  originalTenant,
  onFieldChanged,
}: TenantFormDetailsPageProps) => {
  const { t } = useTranslation();

  return (
    <div className="tenant-form-details-page">
      <div id="input-container" className="tenant-form-details-page-inputs">
        <FormField
          title={t("tenant_id", { ns: "tenant" })}
          name="id"
          disabled
          value={originalTenant?.id}
          onValueChanged={onFieldChanged}
          hintText={t("tenant_id_hint", { ns: "tenant" })}
        />

        <FormField
          title={t("name")}
          name="name"
          required
          value={tenant.name ?? ""}
          onValueChanged={onFieldChanged}
        />

        <FormField
          title={t("business_id", { ns: "tenant" })}
          name="businessId"
          value={tenant.businessId ?? ""}
          onValueChanged={onFieldChanged}
          required
        />

        <FormField
          title={t("address", { ns: "contact" })}
          name="address"
          value={tenant.address ?? ""}
          onValueChanged={onFieldChanged}
        />

        <div className="tenant-form-details-page-phone-row">
          <PhoneNumberField
            title={t("phone", { ns: "contact" })}
            name="phoneNr"
            value={tenant.phoneNr ?? null}
            required
            handleChange={onFieldChanged}
          />
        </div>

        <FormField
          title={t("email", { ns: "contact" })}
          name="email"
          type="email"
          value={tenant.email ?? ""}
          onValueChanged={onFieldChanged}
        />
      </div>
    </div>
  );
};
