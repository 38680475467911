import { ReactNode } from "react";
import "./ContextMenu.css";

type ContextMenuItemDef = {
  id: string;
  title: string;
  icon?: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  onClicked: (id: string) => void;
};

interface ContextMenuProps {
  items: ContextMenuItemDef[];
}

export const ContextMenu = ({ items }: ContextMenuProps) => {
  return (
    <div className="context-menu">
      <button type="button">...</button>
      <div className="context-menu-items">
        {items
          .filter((item) => !item.hidden)
          .map((item, i) => (
            <div
              key={i}
              className={`context-menu-item ${item.disabled ? "disabled" : ""}`}
              role="button"
              onClick={() => item.onClicked(item.id)}
            >
              <div className="context-menu-item-icon">{item.icon}</div>
              {item.title}
            </div>
          ))}
      </div>
    </div>
  );
};
