import { useState, useEffect, useRef } from "react";

interface TooltipPosition {
  x: number;
  y: number;
}

export const useTooltip = (checkOverflow: boolean = true) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    x: 0,
    y: 0,
  });
  const [isOverflowing, setIsOverflowing] = useState(false);
  const elementRef = useRef<HTMLDivElement | HTMLInputElement | null>(null);

  const checkElementOverflow = (element: HTMLDivElement | HTMLInputElement) => {
    const isOverflowing =
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight;
    setIsOverflowing(isOverflowing);
  };

  const handleMouseMove = (e: MouseEvent) => {
    setTooltipPosition({
      x: e.clientX + 10,
      y: e.clientY + 10,
    });
  };

  useEffect(() => {
    const node = elementRef.current;

    if (!node) return;

    checkElementOverflow(node);

    const handleMouseOver = () => {
      if (checkOverflow && isOverflowing) {
        setTooltipVisible(true);
      } else {
        setTooltipVisible(false);
      }
    };

    const handleMouseLeave = () => {
      setTooltipVisible(false);
    };

    node.addEventListener("mouseover", handleMouseOver);
    node.addEventListener("mouseleave", handleMouseLeave);
    node.addEventListener("mousemove", handleMouseMove as EventListener);

    return () => {
      node.removeEventListener("mouseover", handleMouseOver);
      node.removeEventListener("mouseleave", handleMouseLeave);
      node.removeEventListener("mousemove", handleMouseMove as EventListener);
    };
  }, [isOverflowing, checkOverflow]);

  return {
    tooltipVisible,
    tooltipPosition,
    elementRef,
  };
};
