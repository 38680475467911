import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import "./Home.css";

const Home = () => {
  const { cliftState } = useCliftContext();
  const { t } = useTranslation();

  return (
    <>
      <div id="home">
        <div className="home-content">
          {t("welcome_to_connected_lift_console")}
          <br />
          <div>
            {cliftState.tenants?.length === 0 && t("no_tenants_contact_admin")}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
