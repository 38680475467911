import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import FormField from "../../../components/FormField";
import SideModal from "../../../components/SideModal";
import { ToggleSwitch } from "../../../components/ToggleSwitch";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import {
  Device,
  DeviceSettings,
  DeviceUILanguage,
  SubscriptionState,
} from "../../../models/device";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../../services/clift-api-errors";
import { editDeviceSettings } from "../../../services/device-settings-api";
import "./DeviceSettingsForm.css";

const DEFAULT_SETTINGS = {
  id: 0,
  subscriptionStartDate: new Date("2024-10-20").toUTCString(),
  subscriptionEndDate: new Date("2025-10-20").toUTCString(),
  subscriptionState: SubscriptionState.UNREGISTERED,
  deviceUiLanguage: DeviceUILanguage.ENGLISH,
  liftGuiTelemetryEnabled: true,
  liftEcuUpdateInterval: 1000,
  liftEcuTelemetryEnabled: true,
  batteryVoltageReadInterval: 1000,
  networkStateTelemetryEnabled: true,
  networkStateUpdateInterval: 1000,
  runtimeInfoUpdateInterval: 1000,
  runtimeInfoTelemetryEnabled: true,
  temperatureUpdateInterval: 1000,
  temperatureTelemetryEnabled: true,
};

interface DeviceSettingsFormProps {
  tenantID: number;
  device: Device;
}

export const DeviceSettingsForm = ({
  tenantID,
  device,
}: DeviceSettingsFormProps) => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();
  const [settings, setSettings] = useState<DeviceSettings>(DEFAULT_SETTINGS);
  const [hasChanged, setChanged] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onValueChanged = (
    name: string,
    value: string | number | boolean | Date | null
  ) => {
    setChanged(true);
    setSettings({
      ...settings,
      [name]: value as string,
    });
  };

  const onClose = () => {
    if (hasChanged) {
      setCancelled(true);
    } else {
      closeSidePanel();
    }
  };

  const onSubmit = (deviceSettings: DeviceSettings) => {
    if (!deviceSettings) onClose();
    const requestBody = { ...deviceSettings };
    return editDeviceSettings(tenantID, device.id, requestBody)
      .then(() => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_settings_update_success", {
            ns: "alerts",
            deviceId: device.id,
          }),
        });
        toast.success(
          t("changes_saved", {
            ns: "toasts",
          })
        );
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("device_settings_update_failed", {
            ns: "alerts",
            deviceId: device.id,
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("edit_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  useEffect(() => {
    setSettings(
      device.deviceSettings ? device.deviceSettings : DEFAULT_SETTINGS
    );
  }, [device.deviceSettings]);

  const onSave = () => {
    onSubmit(settings);
  };

  const bottomButtonsProps = {
    onCancel: onClose,
    showSave: true,
    onSave: onSave,
  };

  return (
    <SideModal
      title={t("device_edit_settings", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      onClose={onClose}
    >
      <div className="device-settings-form">
        <form>
          <div className="device-settings-form-lines">
            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("lift_gui_telemetry_enabled", { ns: "lift" })}
              </div>
              <div className="device-settings-form-edit-column">
                <ToggleSwitch
                  on={settings.liftGuiTelemetryEnabled}
                  onChange={(on) =>
                    onValueChanged("liftGuiTelemetryEnabled", on)
                  }
                />
              </div>
            </div>

            <div className="device-settings-form-subtitle">
              {t("update_intervals", { ns: "lift" })}
            </div>

            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("stairlift_state", { ns: "lift" })}
              </div>
              <div className="device-settings-form-edit-column">
                <div className="device-settings-form-edit-column-switch">
                  <ToggleSwitch
                    on={settings.liftEcuTelemetryEnabled}
                    onChange={(on) =>
                      onValueChanged("liftEcuTelemetryEnabled", on)
                    }
                  />
                </div>

                <FormField
                  name={"liftEcuUpdateInterval"}
                  title=""
                  type="number"
                  min={1}
                  disabled={!settings.liftEcuTelemetryEnabled}
                  value={settings.liftEcuUpdateInterval ?? ""}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>

            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("network_state_update_interval", { ns: "lift" })}
              </div>

              <div className="device-settings-form-edit-column">
                <div className="device-settings-form-edit-column-switch">
                  <ToggleSwitch
                    on={settings.networkStateTelemetryEnabled}
                    onChange={(on) =>
                      onValueChanged("networkStateTelemetryEnabled", on)
                    }
                  />
                </div>
                <FormField
                  name={"networkStateUpdateInterval"}
                  title=""
                  type="number"
                  min={1}
                  disabled={!settings.networkStateTelemetryEnabled}
                  value={settings.networkStateUpdateInterval ?? ""}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>

            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("runtime_info_update_interval", { ns: "lift" })}
              </div>

              <div className="device-settings-form-edit-column">
                <div className="device-settings-form-edit-column-switch">
                  <ToggleSwitch
                    on={settings.runtimeInfoTelemetryEnabled}
                    onChange={(on) =>
                      onValueChanged("runtimeInfoTelemetryEnabled", on)
                    }
                  />
                </div>
                <FormField
                  name={"runtimeInfoUpdateInterval"}
                  title=""
                  type="number"
                  min={1}
                  disabled={!settings.runtimeInfoTelemetryEnabled}
                  value={settings.runtimeInfoUpdateInterval ?? ""}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>

            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("temperature_update_interval", { ns: "lift" })}
              </div>

              <div className="device-settings-form-edit-column">
                <div className="device-settings-form-edit-column-switch">
                  <ToggleSwitch
                    on={settings.temperatureTelemetryEnabled}
                    onChange={(on) =>
                      onValueChanged("temperatureTelemetryEnabled", on)
                    }
                  />
                </div>
                <FormField
                  name={"temperatureUpdateInterval"}
                  title=""
                  type="number"
                  min={1}
                  disabled={!settings.temperatureTelemetryEnabled}
                  value={settings.temperatureUpdateInterval ?? ""}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>

            <div className="device-settings-form-row">
              <div className="device-settings-form-row-title">
                {t("battery_voltage_read_interval", { ns: "lift" })}
              </div>
              <div className="device-settings-form-edit-column">
                <FormField
                  name={"batteryVoltageReadInterval"}
                  title=""
                  type="number"
                  min={1}
                  value={settings.batteryVoltageReadInterval ?? ""}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
        </form>

        <ConfirmDialog
          isOpen={cancelled}
          onCancel={() => {
            setCancelled(false);
          }}
          onConfirm={() => {
            closeSidePanel();
          }}
        />
      </div>
    </SideModal>
  );
};
