import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { ViewTenantAdminsAllowedRoles } from "../../models/role";
import { Contact } from "../../models/contact";
import {
  TenantResponseFull,
  TenantAdmin,
  TenantFormValues,
  TenantType,
} from "../../models/tenant";
import {
  fetchChildTenant,
  fetchTenant,
  updateChildTenant,
  updateTenant,
} from "../../services/tenants-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import SideModal from "../../components/SideModal";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import { TenantForm } from "./TenantForm";

export interface EditTenantProps {
  page?: number | undefined;
  child: boolean;
  tenantID: number;
}

export const EditTenant = ({ page, child, tenantID }: EditTenantProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [currentPageIndex, setCurrentPageIndex] = useState(page ?? 0);
  const [tenant, setTenant] = useState<TenantResponseFull>();
  const { t } = useTranslation();
  const [bottomButtonsProps, setBottomButtonsProps] =
    useState<SideModalButtonBarProps>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  useEffect(() => {
    if (!cliftState.currentTenant?.id) {
      return;
    }
    if (child) {
      fetchChildTenant(cliftState.currentTenant?.id, tenantID)
        .then((res) => {
          setTenant(res);
        })
        .catch((err) => {
          closeSidePanel();
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    } else {
      fetchTenant(tenantID)
        .then((res) => {
          setTenant(res);
        })
        .catch((err) => {
          closeSidePanel();
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }

    dispatchCliftState({
      type: CliftReducerAction.FetchTenantUsers,
      tenantID,
    });
  }, [tenantID, cliftState.currentTenant?.id]);

  if (!tenant) return <div />;

  const onUpdateTenant = (
    formValues: TenantFormValues,
    admins: TenantAdmin[],
    contacts: Contact[]
  ) => {
    if (!cliftState.currentTenant) {
      throw new Error("No current tenant");
    }

    const updatedTenant = {
      ...formValues,
      admins,
      contacts,
    };
    let updateFunction;
    if (child) {
      updateFunction = updateChildTenant;
    } else {
      updateFunction = updateTenant;
    }
    return updateFunction(tenant, tenant.parentTenantId ?? null, updatedTenant)
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert:
            tenant.tenantType === TenantType.COUNTRY
              ? t("country_edit_success", { ns: "alerts" })
              : t("lift_provider_edit_success", { ns: "alerts" }),
        });
        toast.success(t("changes_saved", { ns: "toasts" }));
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert:
            tenant.tenantType === TenantType.COUNTRY
              ? t("country_edit_failed", {
                  ns: "alerts",
                  code: getErrorCode(err),
                })
              : t("lift_provider_edit_failed", {
                  ns: "alerts",
                  code: getErrorCode(err),
                }),
        });
        toast.error(
          t("error") +
            t("edit_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={
        tenant.tenantType === TenantType.COUNTRY
          ? t("edit_market_tenant", { ns: "tenant" })
          : t("edit_lift_provider", { ns: "tenant" })
      }
      showPager
      pageCount={isRoleForAction(ViewTenantAdminsAllowedRoles) ? 3 : 2}
      pageIndex={currentPageIndex}
      onClose={closeSidePanel}
      bottomButtonsProps={bottomButtonsProps}
    >
      <TenantForm
        pageIndex={currentPageIndex}
        initialValues={tenant}
        showAdminsPage={isRoleForAction(ViewTenantAdminsAllowedRoles)}
        onSubmit={onUpdateTenant}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
        editingTenant={tenant}
        setBottomButtonsProps={setBottomButtonsProps}
      />
    </SideModal>
  );
};
