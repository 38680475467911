import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EntityPicker, Option } from "../../../components/EntityPicker";
import SideModal from "../../../components/SideModal";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import {
  ContactType,
  DependableContactRequest,
  EditContactInfoRequest,
} from "../../../models/contact";
import { ContactInfo } from "../../../models/device";
import {
  fetchContacts,
  fetchTenantsContacts,
} from "../../../services/contacts-api";
import { patchPhoneNumbers } from "../../../services/devices-api";
import "./EditContactInfo.css";
import "./LiftDetails.css";
import { toast } from "react-toast";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../../services/clift-api-errors";

export interface EditContactInfoProps {
  deviceId: number;
  initialContactInfo: ContactInfo;
}

const toEditRequest = (contactInfo: ContactInfo): EditContactInfoRequest => {
  return {
    speedDialContactId: contactInfo?.speedDialNumber?.id,
    emergencyNumberContactId: contactInfo?.emergencyNumber?.id,
    supportNumberContactId: contactInfo?.supportNumber?.id,
    dependableContacts: contactInfo?.dependableContacts?.map((c) => {
      return {
        priority: c.priority,
        contactId: c.contactId,
      } as DependableContactRequest;
    }),
  } as EditContactInfoRequest;
};

export const EditContactInfo = ({
  deviceId,
  initialContactInfo,
}: EditContactInfoProps) => {
  const { t } = useTranslation();
  const [hasChanged, setChanged] = useState(false);
  const { dispatchCliftState, cliftState } = useCliftContext();
  const [editedContactInfo, setContactInfo] = useState<EditContactInfoRequest>(
    toEditRequest(initialContactInfo)
  );
  const [customerContactOptions, setCustomerContactOptions] = useState<
    Option[]
  >([]);
  const [tenantContactOptions, setTenantContactOptions] = useState<Option[]>(
    []
  );

  const handleClose = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const editDependableContact = (priority: number, id: number) => {
    setChanged(true);
    let dependableContacts = editedContactInfo.dependableContacts;
    const existing = dependableContacts.find((dc) => dc.priority === priority);
    if (existing) {
      existing.contactId = id;
    } else {
      dependableContacts.push({
        contactId: id,
        priority,
      });
    }
    // remove empty contacts
    dependableContacts = dependableContacts.filter(
      (item) => item.contactId != 0
    );
    setContactInfo({
      ...editedContactInfo,
      dependableContacts,
    });
  };

  const getDependableContactPicker = (priority: number) => {
    return (
      <EntityPicker
        noCurrentText={t("select_dependable_person", { ns: "lift" })}
        noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
        items={[
          { label: t("empty_display_name", { ns: "lift" }), id: 0 },
          ...customerContactOptions,
        ]}
        currentId={
          editedContactInfo.dependableContacts?.find(
            (c) => c.priority == priority
          )?.contactId
        }
        onSelect={function (id: number): void {
          editDependableContact(priority, id);
        }}
      />
    );
  };

  useEffect(() => {
    if (!cliftState.currentTenant) {
      return;
    }
    fetchContacts(cliftState.currentTenant!.id, initialContactInfo.customerId)
      .then((res) =>
        setCustomerContactOptions(
          res.map((c) => {
            return {
              label: [c.displayName, c.firstName, c.lastName, c.phoneNr].join(
                ", "
              ),
              id: c.id,
            };
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
    fetchTenantsContacts(cliftState.currentTenant!.id)
      .then((res) =>
        setTenantContactOptions(
          res.map((c) => {
            const label =
              c.contactType === ContactType.EMERGENCY
                ? c.phoneNr
                : [c.displayName, c.phoneNr].join(", ");
            return {
              label: label,
              id: c.id,
            };
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }, [cliftState.currentTenant]);

  const onSave = () => {
    patchPhoneNumbers(
      cliftState.currentTenant?.id || 0,
      deviceId,
      editedContactInfo
    )
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.CloseSidePanel,
        });
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("contacts_update_success", {
            ns: "alerts",
          }),
        });
        toast.success(
          t("changes_saved", {
            ns: "toasts",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("contacts_update_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("edit_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  const bottomButtonsProps = {
    onCancel: handleClose,
    showSave: true,
    saveDisabled:
      !hasChanged ||
      !editedContactInfo.emergencyNumberContactId ||
      !editedContactInfo.speedDialContactId ||
      !editedContactInfo.supportNumberContactId,
    onSave: onSave,
  };

  return (
    <SideModal
      title={t("add_stairlift_phone_numbers", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      onClose={handleClose}
    >
      <div className="lift-contact-form-subtitle">
        <h3>{t("calls", { ns: "lift" })}</h3>
        <h4>{t("add_phone_numbers", { ns: "lift" })}</h4>
      </div>
      <div className="one-below-another">
        <div className="lift-details-section">
          {/* SPEED DIAL */}
          <div className="lift-contact-form-row-title">
            {t("speed_dial", { ns: "lift" })}
          </div>
          <EntityPicker
            noCurrentText={t("select_contact", { ns: "lift" })}
            noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
            items={tenantContactOptions}
            required={true}
            currentId={editedContactInfo.speedDialContactId}
            onSelect={function (id: number): void {
              setChanged(true);
              setContactInfo({
                ...editedContactInfo,
                speedDialContactId: id,
              });
            }}
          />
          {/* EMERGENCY NUMBER */}
          <div className="lift-contact-form-row-title">
            {t("emergency", { ns: "lift" })}
          </div>
          <EntityPicker
            noCurrentText={t("select_contact", { ns: "lift" })}
            noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
            required={true}
            disabled={true}
            items={tenantContactOptions}
            currentId={editedContactInfo.emergencyNumberContactId}
            onSelect={function (id: number): void {
              setChanged(true);
              setContactInfo({
                ...editedContactInfo,
                emergencyNumberContactId: id,
              });
            }}
          />

          {/* SUPPORT NUMBER */}
          <div className="lift-details-section">
            <div className="lift-contact-form-row-title">
              {t("support_number", { ns: "lift" })}
            </div>
            <EntityPicker
              noCurrentText={t("select_contact", { ns: "lift" })}
              noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
              items={tenantContactOptions}
              required={true}
              currentId={editedContactInfo.supportNumberContactId}
              onSelect={function (id: number): void {
                setChanged(true);
                setContactInfo({
                  ...editedContactInfo,
                  supportNumberContactId: id,
                });
              }}
            />
          </div>
        </div>

        <div className="lift-details-section">
          <div className="info-titles">
            <div className="lift-contact-form-row-title">
              {" "}
              {t("dependable_contacts_edit_title", { ns: "lift" })}
            </div>
            <div className="lift-contact-form-row-hint">
              {t("optional", { ns: "lift" })}
            </div>
          </div>

          {/* DP 0 */}
          {getDependableContactPicker(1)}
          {/* DP 1 */}
          {getDependableContactPicker(2)}
          {/* DP 2 */}
          {getDependableContactPicker(3)}
          {/* DP 3 */}
          {getDependableContactPicker(4)}
          {/* DP 4 */}
          {getDependableContactPicker(5)}
        </div>
      </div>
    </SideModal>
  );
};
