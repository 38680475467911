import { useEffect, useState } from "react";
import { toast } from "react-toast";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { deleteTenant, fetchTenants } from "../../services/tenants-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { TenantListItem } from "../../models/tenant-list-item";
import { TenantType } from "../../models/tenant";
import { AddTenantAllowedRoles } from "../../models/role";
import Page from "../../components/Page";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { TenantList } from "./TenantList";

export const TenantsPage = () => {
  const { t } = useTranslation();
  const isAddTenantAllowed: boolean = isRoleForAction(AddTenantAllowedRoles);
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [childTenants, setChildTenants] = useState<TenantListItem[]>();
  const [selectedTenant, setSelectedTenant] = useState<TenantListItem>();

  useEffect(() => {
    if (cliftState.currentTenant && !childTenants) {
      fetchTenants(cliftState.currentTenant.id)
        .then((res) => {
          setChildTenants(res ?? []);
        })
        .catch((err) => {
          setChildTenants([]);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [cliftState.currentTenant, childTenants, cliftState.sidepanelContent]);

  useEffect(() => {
    if (!cliftState.sidepanelContent) {
      setChildTenants(undefined);
    }
  }, [cliftState.tenants, cliftState.sidepanelContent]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!childTenants) {
    return <progress></progress>;
  }

  const handleDeleteTenant = async () => {
    if (!selectedTenant) return;

    await deleteTenant(cliftState.currentTenant!.id, selectedTenant?.id)
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert:
            cliftState.currentTenant?.tenantType === TenantType.ROOT
              ? t("country_delete_success", {
                  ns: "alerts",
                  countryId: selectedTenant?.id,
                })
              : t("lift_provider_delete_success", {
                  ns: "alerts",
                  liftProviderId: selectedTenant?.id,
                }),
        });
        toast.success(
          cliftState.currentTenant?.tenantType === TenantType.ROOT
            ? t("country_deleted", {
                ns: "toasts",
                countryId: selectedTenant?.id,
              })
            : t("lift_provider_deleted", {
                ns: "toasts",
                liftProviderId: selectedTenant?.id,
              })
        );
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert:
            cliftState.currentTenant?.tenantType === TenantType.ROOT
              ? t("country_delete_failed", {
                  ns: "alerts",
                  countryId: selectedTenant?.id,
                  code: getErrorCode(err),
                })
              : t("lift_provider_delete_failed", {
                  ns: "alerts",
                  countryId: selectedTenant?.id,
                  code: getErrorCode(err),
                }),
        });
        toast.error(
          t("error") +
            t("delete_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });

    setSelectedTenant(undefined);
    dispatchCliftState({
      type: CliftReducerAction.FetchTenants,
    });
  };

  return (
    <Page
      title={
        cliftState.currentTenant?.tenantType === TenantType.ROOT
          ? t("markets", { ns: "tenant" })
          : t("lift_providers", { ns: "tenant" })
      }
      subtitle={
        cliftState.currentTenant?.tenantType === TenantType.ROOT
          ? t("markets_subtitle", { ns: "tenant" })
          : t("lift_providers_subtitle", { ns: "tenant" })
      }
      button={
        isAddTenantAllowed ? (
          <button
            className="button"
            type="button"
            id="new_tenant_btn"
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.AddTenant,
              });
            }}
          >
            <PlusIcon />

            {cliftState.currentTenant?.tenantType === "ROOT"
              ? t("new_market_btn", { ns: "tenant" })
              : t("new_provider_btn", { ns: "tenant" })}
          </button>
        ) : null
      }
    >
      <>
        {typeof cliftState.currentTenant !== "undefined" ? (
          <TenantList
            parentTenant={cliftState.currentTenant.id}
            tenants={childTenants}
            setSelectedTenant={(tenant: TenantListItem | undefined) => {
              setSelectedTenant(tenant);
            }}
          />
        ) : (
          <div></div>
        )}

        {selectedTenant && (
          <ConfirmDelete
            title={
              cliftState.currentTenant?.tenantType === "ROOT"
                ? t("delete_market_question", { ns: "dialogs" })
                : t("delete_lift_provider_question", { ns: "dialogs" })
            }
            text={
              cliftState.currentTenant?.tenantType === "ROOT"
                ? t("confirm_market_delete_question", {
                    ns: "dialogs",
                    tenant: selectedTenant.id,
                  })
                : t("confirm_lift_provider_delete_question", {
                    ns: "dialogs",
                    tenant: selectedTenant.id,
                  })
            }
            isOpen={!!selectedTenant}
            onCancel={() => setSelectedTenant(undefined)}
            onConfirmed={handleDeleteTenant}
          />
        )}
      </>
    </Page>
  );
};
