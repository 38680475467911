import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLoginUrl } from "../../utils/url-helper";
import { useCliftContext } from "../../hooks/useCliftContext";
import { ReactComponent as MicrosoftLogo } from "../../assets/MicrosoftLogo.svg";
import LogoPlus from "../../assets/LogoPlus.svg";
import "./Login.css";

const Login = () => {
  const { currentUser } = useCliftContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.clientPrincipal?.userRoles?.includes("authenticated")) {
      navigate("/");
    }
  }, [currentUser]);

  return (
    <div id="login">
      <div className="login-content">
        <img alt="StannahPlus" id="logo" src={LogoPlus} />
        <div className="login-content-form">
          <div className="login-content-form-title">
            {t("sign_in", { ns: "profile" })}
          </div>
          <div className="login-content-form-subtitle">
            {t("sign_in_admin_access", { ns: "profile" })}
          </div>
          <button className="button">
            <MicrosoftLogo />
            <a id="login-microsoft-link" href={getLoginUrl()}>
              {t("sign_in_with_microsoft", { ns: "profile" })}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
