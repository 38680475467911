import { useState } from "react";
import "./Tab.css";

export interface TabProps {
  tabs: Array<{ name: string; content: React.ReactNode }>;
}

const Tab = ({ tabs }: TabProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const onSwitchTab = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className="tab">
      <ul className="tab-list">
        {tabs.map((tab, index) => (
          <li key={`tab-${index}`}>
            <button
              className={`${
                index === activeTabIndex ? "active" : "inactive"
              } tab-list-item`}
              tabIndex={index}
              onClick={() => {
                onSwitchTab(index);
              }}
            >
              {tab.name}
            </button>
          </li>
        ))}
      </ul>

      <div className="tab-content">{tabs[activeTabIndex]?.content}</div>
    </div>
  );
};

export default Tab;
