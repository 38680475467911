import FormField from "../../components/FormField";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { ReactComponent as HelpIcon } from "../../assets/Help.svg";
import { Customer, CustomerFormValues } from "../../models/customer";
import "./CustomerForm.css";
import { Tooltip } from "react-tooltip";
import { Contact, ContactType } from "../../models/contact";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import PhoneNumberField from "../../components/PhoneNumberField";

export const enum CreateCustomerPages {
  CustomerPage,
  CustomerContactsPage,
}

const MAX_CONTACTS = 50;

export interface CustomerFormProps {
  onSubmit: (
    customer: CustomerFormValues,
    contacts: Contact[]
  ) => Promise<void>;
  onClose: () => void;
  onSwitchPage: (pageIndex: number) => void;
  pageIndex: number;
  initialValues?: CustomerFormValues;
  editingCustomer?: Customer | undefined;
  showSaveOnFirstPage?: boolean;
  setBottomButtonsProps: (buttonsProps: SideModalButtonBarProps) => void;
}

export const CustomerForm = ({
  onSubmit,
  onClose,
  onSwitchPage,
  pageIndex,
  initialValues = {
    firstName: null,
    lastName: null,
    organization: null,
    vat: null,
    email: null,
    phoneNr: null,
    address: null,
    apartmentNr: null,
    zipCode: null,
    city: null,
    region: null,
    country: null,
    contacts: [],
    customerIsAccountOwner: false,
    accountOwner: {
      email: null,
      firstName: null,
      lastName: null,
      phoneNr: null,
    },
  },
  editingCustomer,
  showSaveOnFirstPage = false,
  setBottomButtonsProps,
}: CustomerFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [customer, setCustomer] = useState(initialValues);
  const customerForm = useRef<HTMLFormElement>(null);
  const [contacts, setContacts] = useState<Contact[]>(
    initialValues.contacts || []
  );

  const onHandleFieldChanged = (name: string, value: string | number) => {
    setChanged(true);
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const onHandleAccountOwnerChanged = (
    name: string,
    value: string | number
  ) => {
    setChanged(true);
    setCustomer({
      ...customer,
      accountOwner: {
        ...customer.accountOwner,
        [name]: value,
      },
    });
  };

  const isAccountOwnerRequiredFieldFilled = !customer.customerIsAccountOwner
    ? customer.accountOwner?.firstName &&
      customer.accountOwner?.lastName &&
      customer.accountOwner?.email
    : true;

  const isCustomersRequiredFieldsFilled = (): boolean => {
    if (
      customer.firstName &&
      customer.lastName &&
      customer.email &&
      customer.phoneNr &&
      customer.address &&
      customer.city &&
      isAccountOwnerRequiredFieldFilled
    ) {
      return true;
    }
    return false;
  };

  const isContactsRequiredFieldsFilled = (): boolean => {
    if (contacts[0] && contacts[0].displayName && contacts[0].phoneNr) {
      return true;
    }
    return false;
  };

  const onHandleContactChanged = (
    name: string,
    value: string | number,
    index: number
  ) => {
    setChanged(true);
    const newContacts = contacts.map((contact, i) => {
      if (i === index) {
        return {
          ...contact,
          [name]: value as string,
        };
      }
      return contact;
    });
    setContacts(newContacts);
  };

  const handleChange = () => {
    setChanged(true);
    setCustomer({
      ...customer,
      customerIsAccountOwner: !customer.customerIsAccountOwner,
    });
  };

  const onNext = () => {
    if (customerForm.current?.reportValidity()) {
      onSwitchPage(CreateCustomerPages.CustomerContactsPage);
    }
  };

  const onBack = () => {
    onSwitchPage(CreateCustomerPages.CustomerPage);
  };

  const onCancel = () => {
    if (hasChanged) {
      setCanceled(true);
    } else {
      onClose();
    }
  };

  const onSave = () => {
    onSubmit(customer, contacts);
  };

  const isCustomerPage = pageIndex === CreateCustomerPages.CustomerPage;
  const isContactPage = pageIndex === CreateCustomerPages.CustomerContactsPage;

  const bottomButtonsProps = {
    onCancel: onCancel,
    nextDisabled: isCustomerPage && !isCustomersRequiredFieldsFilled(),
    saveDisabled:
      !hasChanged ||
      (isContactPage && !isContactsRequiredFieldsFilled()) ||
      (isCustomerPage && !isCustomersRequiredFieldsFilled()),
    ...(isCustomerPage ? { onNext } : { onBack }),
    showSave: isCustomerPage ? showSaveOnFirstPage : true,
    onSave: onSave,
  };

  useEffect(() => {
    setBottomButtonsProps(bottomButtonsProps);
  }, [pageIndex, customer, contacts]);

  return (
    <div className="customer-form">
      <form ref={customerForm}>
        {pageIndex === CreateCustomerPages.CustomerPage && (
          <div className="customer-form-customer-page">
            <div className="customer-form-inputs">
              <FormField
                title={t("customer_id", { ns: "customer" })}
                name="customerId"
                disabled
                value={editingCustomer?.id}
                onValueChanged={onHandleFieldChanged}
                hintText={t("customer_id_hint", { ns: "customer" })}
              />

              <h3>{t("lift_owner", { ns: "customer" })}</h3>
              <h2>{t("lift_owner_info", { ns: "customer" })}</h2>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("first_name", { ns: "customer" })}
                  name="firstName"
                  required
                  value={customer.firstName ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  className="adjacent-formfield"
                  required
                  value={customer.lastName ?? ""}
                  title={t("last_name", { ns: "customer" })}
                  name="lastName"
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("organization", { ns: "customer" })}
                  name="organization"
                  value={customer.organization ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  className="adjacent-formfield"
                  value={customer.vat ?? ""}
                  title={t("vat", { ns: "customer" })}
                  name="vat"
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-email-row">
                <FormField
                  title={t("email", { ns: "customer" })}
                  name="email"
                  type="email"
                  disabled={!!editingCustomer}
                  value={customer.email ?? ""}
                  onValueChanged={onHandleFieldChanged}
                  required={true}
                />
              </div>
              <div className="customer-form-creation-phone-row">
                <PhoneNumberField
                  title={t("phone_nr", { ns: "customer" })}
                  name="phoneNr"
                  value={customer.phoneNr ?? null}
                  required={true}
                  handleChange={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("address", { ns: "customer" })}
                  name="address"
                  value={customer.address ?? ""}
                  onValueChanged={onHandleFieldChanged}
                  required={true}
                />

                <FormField
                  className="adjacent-formfield"
                  title={t("apartment_nr", { ns: "customer" })}
                  name="apartmentNr"
                  value={customer.apartmentNr ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("city", { ns: "customer" })}
                  name="city"
                  value={customer.city ?? ""}
                  onValueChanged={onHandleFieldChanged}
                  required={true}
                />

                <FormField
                  className="adjacent-formfield"
                  title={t("zip_code", { ns: "customer" })}
                  name="zipCode"
                  value={customer.zipCode ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("region", { ns: "customer" })}
                  name="region"
                  value={customer.region ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  className="adjacent-formfield"
                  title={t("country", { ns: "customer" })}
                  name="country"
                  value={customer.country ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>
            </div>

            <h3>{t("account_owner", { ns: "customer" })}</h3>
            <h2>{t("account_owner_info", { ns: "customer" })}</h2>

            <label className="customer-is-accountowner-checkbox">
              <input
                type="checkbox"
                checked={customer.customerIsAccountOwner}
                onChange={handleChange}
              />
              <span>{t("customer_is_account_owner", { ns: "customer" })}</span>
            </label>
            <div className="customer-form-creation-input-row">
              <FormField
                title={t("first_name", { ns: "customer" })}
                name="firstName"
                required
                disabled={customer.customerIsAccountOwner}
                value={
                  (customer.customerIsAccountOwner
                    ? customer.firstName
                    : customer.accountOwner?.firstName) ?? ""
                }
                onValueChanged={onHandleAccountOwnerChanged}
              />

              <FormField
                className="adjacent-formfield"
                required
                value={
                  (customer.customerIsAccountOwner
                    ? customer.lastName
                    : customer.accountOwner?.lastName) ?? ""
                }
                disabled={customer.customerIsAccountOwner}
                title={t("last_name", { ns: "customer" })}
                name="lastName"
                onValueChanged={onHandleAccountOwnerChanged}
              />
            </div>

            <div className="customer-form-creation-email-row">
              <FormField
                title={t("email", { ns: "customer" })}
                name="email"
                disabled={customer.customerIsAccountOwner}
                required
                value={
                  (customer.customerIsAccountOwner
                    ? customer.email
                    : customer.accountOwner?.email) ?? ""
                }
                onValueChanged={onHandleAccountOwnerChanged}
              />
              <div>
                <HelpIcon id="help-icon-customer-form" />
              </div>
            </div>
            <Tooltip id="my-tooltip" anchorSelect="#help-icon-customer-form">
              {t("email_tooltip", { ns: "customer" })}
            </Tooltip>

            <div className="customer-form-creation-phone-row">
              <PhoneNumberField
                title={t("phone_nr", { ns: "customer" })}
                name="phoneNr"
                value={
                  (customer.customerIsAccountOwner
                    ? customer.phoneNr
                    : customer.accountOwner?.phoneNr) ?? null
                }
                disabled={customer.customerIsAccountOwner}
                handleChange={onHandleAccountOwnerChanged}
              />
            </div>
          </div>
        )}

        {pageIndex === CreateCustomerPages.CustomerContactsPage && (
          <div className="customer-form-contact-page">
            <div className="customer-form-creation-row">
              <div>
                <h3>{t("customer_contacts", { ns: "customer" })}</h3>
                <h4>{t("customer_contacts_hint", { ns: "customer" })}</h4>
              </div>

              <button
                className="button"
                type="button"
                disabled={contacts.length >= MAX_CONTACTS}
                onClick={(e) => {
                  e.preventDefault();
                  setContacts([
                    {
                      displayName: "",
                      firstName: "",
                      lastName: "",
                      phoneNr: "",
                      contactType: ContactType.CUSTOMER_CONTACT,
                    },
                    ...(contacts || []),
                  ]);
                  customerForm.current?.reset();
                }}
              >
                <PlusIcon />
                {t("add_new_contact", { ns: "customer" })}
              </button>
            </div>
            {(contacts || []).map((contact, i) => (
              <div key={i} className="customer-form-contact-users">
                <FormField
                  title="Display name"
                  name="displayName"
                  value={contact.displayName}
                  maxLength={10}
                  required={true}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <FormField
                  title="First name"
                  name="firstName"
                  value={contact.firstName}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <FormField
                  title="Last name"
                  name="lastName"
                  value={contact.lastName}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />

                <PhoneNumberField
                  title={t("phone_nr", { ns: "customer" })}
                  name="phoneNr"
                  value={contact.phoneNr}
                  required={true}
                  handleChange={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />

                <button
                  className=" delete-contact-button"
                  title={t("delete", { ns: "translation" })}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setChanged(true);
                    setContacts(contacts.toSpliced(i, 1));
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <div className="customer-form-errors"></div>
          </div>
        )}
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
