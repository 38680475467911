import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExportIcon } from "../assets/Export.svg";
import { ReactComponent as LeftArrow } from "../assets/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../assets/RightArrow.svg";
import { ReactComponent as SearchIcon } from "../assets/Search.svg";
import { SearchParams, Sort } from "../models/search";
import "./PaginatedList.css";

export interface ListProps<T> {
  data: T[];
  columns: ColumnDef<T, string>[];
  noDataComponent?: ReactNode;
  sort: Sort;
  //this number is changed if page should be updated by some outside trigger
  pageUpdateTrigger?: number;
  fetchData: (search: SearchParams) => unknown;
  onExport?: () => void;
  totalElements?: number;
}

const PaginatedList = <T,>({
  data,
  columns,
  noDataComponent,
  sort,
  pageUpdateTrigger,
  onExport,
  fetchData,
  totalElements,
}: ListProps<T>) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState("");

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleExport = (e: MouseEvent) => {
    e.preventDefault();
    onExport?.();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  // sort should reset to first page
  useEffect(() => {
    setCurrentPage(1);
  }, [sort]);

  useEffect(() => {
    fetchData({
      pageNumber: currentPage - 1,
      pageSize: pageSize,
      sort: sort,
      search: searchText,
    });
  }, [currentPage, pageSize, searchText, sort, pageUpdateTrigger]);

  const totalPages = totalElements ? Math.ceil(totalElements / pageSize) : 0;

  return (
    <div className="list">
      <div className="list-tools">
        <div className="list-search">
          <input
            type="search"
            placeholder={t("search_placeholder", { ns: "list" })}
            defaultValue=""
            onChange={handleSearchChange}
          />
          <SearchIcon className="list-search-icon" />
        </div>

        <div className="list-buttons">
          {onExport && (
            <button
              className="export-icon"
              onClick={handleExport}
              type="button"
              title="export"
            >
              <ExportIcon />
            </button>
          )}
        </div>
      </div>

      <div className="list-content">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {!table.getRowModel().rows.length && (
              <tr>
                <td colSpan={table.getVisibleFlatColumns().length}>
                  {noDataComponent ? (
                    noDataComponent
                  ) : (
                    <div className="list-no-data">
                      {t("no-data", { ns: "list" })}
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="page-pagination">
        {totalElements !== undefined && (
          <span className="total-elements">
            {t("current_items", {
              ns: "pagination",
              pageFirstItem: (currentPage - 1) * pageSize + 1,
              pageLastItem: Math.min(currentPage * pageSize, totalElements),
              total: totalElements,
            })}
          </span>
        )}
        <button
          className="arrow"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftArrow />
        </button>
        <span className="current-page-number">{currentPage}</span>
        <button
          className="arrow"
          type="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightArrow />
        </button>
        <select
          value={pageSize}
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
        >
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page (slow)</option>
          <option value={1000}>1000 per page (slow)</option>
        </select>
      </div>
    </div>
  );
};

export default PaginatedList;
