import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import Page from "../../components/Page";
import PaginatedList from "../../components/PaginatedList";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useCsvExport } from "../../hooks/useCsvExport";
import { isRoleForAction } from "../../hooks/useRoles";
import {
  EmployeeListEditAllowedRoles,
  EmployeeListViewAllowedRoles,
  StannahRole,
} from "../../models/role";
import { Direction, SearchParams, Sort } from "../../models/search";
import { EmployeeListItem, EmployeeSortableColumns } from "../../models/user";
import { getErrorCode } from "../../services/clift-api-errors";
import { fetchEmployees } from "../../services/employees-api";
import DeleteUserDialog from "./DeleteUserDialog";
import { EditEmployee } from "./EditEmployee";
import { EmployeeOptionsMenu } from "./EmployeeOptionsMenu";
import { InviteEmployees } from "./InviteEmployees";
import { ReactComponent as DownIcon } from "../../assets/ArrowDown.svg";
import { ReactComponent as UpIcon } from "../../assets/ArrowUp.svg";

export const EmployeeList = () => {
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: EmployeeSortableColumns.ROLE,
  });
  const [totalEmployees, setTotalEmployees] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeListItem[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<
    EmployeeListItem[]
  >([]);
  const [selectedUserID, setSelectedUserID] = useState<number>();
  const [loading] = useState(false);
  const [fetchEmployeesTrigger, setFetchEmployeesTrigger] = useState<number>(0);

  const { cliftState, dispatchCliftState } = useCliftContext();
  const { t, i18n } = useTranslation();

  const columnHelper = createColumnHelper<EmployeeListItem>();

  const getLocalizedRole = (userRole: string): string => {
    if (i18n.exists(userRole, { ns: "role" })) {
      return i18n.t(userRole as StannahRole, { ns: "role" });
    }
    return i18n.t("NO_ROLE", { ns: "role" });
  };

  const handleSort = (column: EmployeeSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const getAllRoles = (userInfo: EmployeeListItem) => {
    const isUserDuplicated =
      employees.filter((e) => e.userAccountId === userInfo.userAccountId)
        .length > 1;

    if (isUserDuplicated) {
      const roles = employees
        .filter(
          (employee) =>
            employee.userAccountId === userInfo.userAccountId && employee.role
        )
        .map((employee) => employee.role as string)
        .filter((role, index, self) => self.indexOf(role) === index);

      const rolesString = roles.map((role) => getLocalizedRole(role));

      return rolesString.map((role) => role).join(", ");
    } else {
      return getLocalizedRole(userInfo.role ?? "");
    }
  };

  const getSortableColumnHeader = (
    column: EmployeeSortableColumns,
    title: string
  ) => {
    const activeColumn = sort.column === column;
    return (
      <div className="sortable-header" onClick={() => handleSort(column)}>
        {title}
        <div className="sortable-header-arrows">
          <UpIcon
            className={`${activeColumn && sort.direction === Direction.ASC ? "active-arrow" : ""} `}
          />
          <DownIcon
            className={`${activeColumn && sort.direction === Direction.DESC ? "active-arrow" : ""} `}
          />
        </div>
      </div>
    );
  };

  const filterDuplicatedUsers = (users: EmployeeListItem[]) => {
    return users.filter(
      (e, index, self) =>
        index === self.findIndex((t) => t.userAccountId === e.userAccountId)
    );
  };

  const updateEmployeeList = () => {
    setFetchEmployeesTrigger(Math.random());
  };

  const onRowClick = (info: CellContext<EmployeeListItem, string>) => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditEmployee
          userId={info.row.original.userAccountId}
          onEditCompleted={updateEmployeeList}
        />
      ),
    });
  };
  const columns: ColumnDef<EmployeeListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () =>
          getSortableColumnHeader(
            EmployeeSortableColumns.NAME,
            t("name", { ns: "translation" })
          ),
        cell: (info) => (
          <div onClick={() => onRowClick(info)}>{info.row.original.name}</div>
        ),
      }),
      columnHelper.accessor("email", {
        header: () =>
          getSortableColumnHeader(
            EmployeeSortableColumns.EMAIL,
            t("email", { ns: "translation" })
          ),
        cell: (info) => (
          <div onClick={() => onRowClick(info)}>{info.row.original.email}</div>
        ),
      }),
      columnHelper.accessor("role", {
        header: () =>
          getSortableColumnHeader(
            EmployeeSortableColumns.ROLE,
            t("role", { ns: "employee" })
          ),
        cell: (info) =>
          isRoleForAction(EmployeeListViewAllowedRoles) && (
            <div onClick={() => onRowClick(info)}>
              {getAllRoles(info.row.original)}
            </div>
          ),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          return (
            isRoleForAction(EmployeeListEditAllowedRoles) && (
              <EmployeeOptionsMenu
                userID={info.row.original.userAccountId}
                triggerUpdate={updateEmployeeList}
                setSelectedUserID={setSelectedUserID}
              />
            )
          );
        },
      }),
    ],
    [i18n.language, sort, employees]
  );

  const handleExport = useCsvExport();

  const fetch = (search: SearchParams) => {
    if (!cliftState.currentTenant) {
      console.error("Current tenant not defined!");
      return;
    }
    return fetchEmployees(cliftState.currentTenant.id, search)
      .then((res) => {
        setTotalEmployees(res.total);
        setEmployees(res.content);
        setFilteredEmployees(filterDuplicatedUsers(res.content));
      })
      .catch((err) => {
        setEmployees([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  if (!loading && cliftState.currentTenant?.id) {
    return (
      <Page
        title={
          t("employees_prefix", { ns: "employee" }) +
          cliftState.currentTenant?.name
        }
        subtitle={t("employees_subtitle", { ns: "employee" })}
        button={
          <button
            className="button"
            type="button"
            disabled={!isRoleForAction(EmployeeListEditAllowedRoles)}
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetSidePanelContent,
                content: (
                  <InviteEmployees onInviteCompleted={updateEmployeeList} />
                ),
              });
            }}
          >
            <PlusIcon />
            {t("invite_new_employee", { ns: "employee" })}
          </button>
        }
      >
        <PaginatedList<EmployeeListItem>
          columns={columns}
          data={filteredEmployees}
          sort={sort}
          fetchData={fetch}
          pageUpdateTrigger={fetchEmployeesTrigger}
          totalElements={totalEmployees}
          onExport={() =>
            handleExport({
              tableName: "employees",
              tenantId: cliftState.currentTenant?.id ?? 0,
            })
          }
        />
        {selectedUserID !== undefined && (
          <DeleteUserDialog
            userId={selectedUserID}
            setSelectedUserId={setSelectedUserID}
            onDeleteCompleted={updateEmployeeList}
          />
        )}
      </Page>
    );
  } else {
    return <progress></progress>;
  }
};
