import { useState } from "react";
import { DeviceRole } from "../../../../models/role";
import FormField, { invalidFormField } from "../../../../components/FormField";
import SideModal from "../../../../components/SideModal";
import { ReactComponent as DeleteIcon } from "../../../../assets/Delete.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Plus.svg";
import { useEditCarers } from "./EditCarersHook";

import "./Carers.css";
import { emailRegex } from "../../../../app/constants";

export interface EditCarersProps {
  deviceId: number;
  initialCarers: DeviceRole[];
}

export type EditCarersFormValues = {
  carers: CarerFormValues[];
};

export type CarerFormValues = {
  userId: number | null;
  email: string;
  firstName: string;
  lastName: string;
};

export const EditCarers = ({ deviceId, initialCarers }: EditCarersProps) => {
  const {
    t,
    handleAddCarer,
    handleEditCarer,
    handleDeleteCarer,
    handleSave,
    handleClose,
    invalidForm,
    carersForm,
  } = useEditCarers(deviceId, initialCarers);
  const [hasChanged, setChanged] = useState(false);

  const bottomButtonsProps = {
    showSave: true,
    saveDisabled: !hasChanged || invalidForm(),
    onCancel: handleClose,
    onSave: handleSave,
  };

  return (
    <SideModal
      title={t("edit_carers_title", { ns: "lift" })}
      bottomButtonsProps={bottomButtonsProps}
      onClose={handleClose}
    >
      <div className="carer-form-upper-row">
        <div>
          <h3>{t("lift_carers", { ns: "lift" })}</h3>
          <h4>{t("lift_carers_add_hint", { ns: "lift" })}</h4>
        </div>
        <div>
          <button
            className="button secondary-button"
            id="create_carer_button"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setChanged(true);
              handleAddCarer();
            }}
          >
            <PlusIcon />
            {t("add_carer", { ns: "lift" })}
          </button>
        </div>
      </div>

      {carersForm.carers.map((carer: CarerFormValues, i: number) => (
        <div key={i} className="carer-form-row">
          <FormField
            title={t("first_name", { ns: "customer" })}
            name={"firstName"}
            type="text"
            autoComplete="off"
            value={carer.firstName}
            onValueChanged={(name, val) => {
              setChanged(true);
              handleEditCarer(name, val as string, i);
            }}
          />
          <FormField
            title={t("last_name", { ns: "customer" })}
            name={"lastName"}
            type="text"
            autoComplete="off"
            value={carer.lastName}
            onValueChanged={(name, val) => {
              setChanged(true);
              handleEditCarer(name, val as string, i);
            }}
          />
          <FormField
            title={t("email", { ns: "contact" })}
            name={"email"}
            type="email"
            className={!emailRegex.test(carer.email) ? invalidFormField : ""}
            disabled={carer.userId != null}
            autoComplete="off"
            value={carer.email}
            onValueChanged={(name, val) => {
              setChanged(true);
              handleEditCarer(name, val as string, i);
            }}
            required
          />

          <button
            title={t("delete", { ns: "translation" })}
            className="delete-carer-button"
            type="button"
            disabled={carersForm.carers.length === 1}
            onClick={(e) => {
              e.preventDefault();
              setChanged(true);
              handleDeleteCarer(i);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
    </SideModal>
  );
};
