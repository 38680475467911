import { toast } from "react-toast";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { CustomerForm } from "./CustomerForm";
import { CustomerFormValues } from "../../models/customer";
import { createCustomer } from "../../services/customers-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { Contact } from "../../models/contact";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";

export interface CreateCustomerProps {
  onCreateCompleted?: () => void;
}

export const CreateCustomer = ({ onCreateCompleted }: CreateCustomerProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { t } = useTranslation();
  const [bottomButtonsProps, setBottomButtonsProps] =
    useState<SideModalButtonBarProps>();

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onCreateCustomer = (
    customer: CustomerFormValues,
    contacts: Contact[]
  ) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);
    const tenantId = cliftState.currentTenant.id;

    return createCustomer(customer, tenantId, contacts)
      .then(() => {
        setLoading(false);
        onCreateCompleted?.();
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_add_success", {
            ns: "alerts",
          }),
        });
        toast.success(
          t("add_customer_success", {
            ns: "toasts",
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_add_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("add_customer_failed", {
              ns: "toasts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={
        currentPageIndex === 0
          ? t("add_customer_title", { ns: "customer" })
          : t("add_contacts_title", { ns: "customer" })
      }
      pageCount={2}
      pageIndex={currentPageIndex}
      bottomButtonsProps={bottomButtonsProps}
      onClose={closeSidePanel}
    >
      <CustomerForm
        pageIndex={currentPageIndex}
        onSubmit={onCreateCustomer}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
        setBottomButtonsProps={setBottomButtonsProps}
      />
    </SideModal>
  );
};
