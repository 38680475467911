import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { DeviceHistoryData, NetworkInfo } from "../../../models/device";
import { getErrorCode } from "../../../services/clift-api-errors";
import {
  fetchHistoryData,
  fetchNetworkInfo,
} from "../../../services/devices-api";
import Tab from "../../../components/Tab";
import LineGraph from "../../../components/LineGraph";
import { ReactComponent as ConnectedIcon } from "../../../assets/Connected.svg";
import { ReactComponent as DisconnectedIcon } from "../../../assets/Disconnected.svg";
import "./NetworkInfoCard.css";

export interface NetworkInfoCardProps {
  tenantId: number | undefined;
  deviceId: number | undefined;
  deviceConnected: boolean;
}

export const NetworkInfoCard = ({
  tenantId,
  deviceId,
  deviceConnected,
}: NetworkInfoCardProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [networkInfo, setNetworkInfo] = useState<NetworkInfo>();
  const [signalStrengthData, setSignalStrengthData] =
    useState<DeviceHistoryData[]>();

  const updateDeviceState = () => {
    if (deviceId !== undefined && !!tenantId) {
      fetchNetworkInfo(tenantId, deviceId)
        .then((info) => {
          setNetworkInfo(info);
        })
        .catch((err) => {
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  };

  const fetchSignalStrengthData = () => {
    if (!cliftState.currentTenant?.id || !deviceId) return;
    return fetchHistoryData(cliftState.currentTenant?.id, deviceId)
      .then((res) => {
        setSignalStrengthData(
          res.filter((data) => data.key === "signalStrength")
        );
      })
      .catch((err) => {
        setSignalStrengthData([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("data_fetch_failed", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    fetchSignalStrengthData();
  }, [deviceId, cliftState.currentTenant]);

  useEffect(() => {
    updateDeviceState();
  }, [deviceId, cliftState.currentTenant, deviceConnected]);

  const onRefresh = () => {
    updateDeviceState();
    fetchSignalStrengthData();
  };

  const tabs = [
    {
      name: t("network_info_title", { ns: "lift" }),
      content: (
        <>
          <div className="network-info-card-current-status">
            <div className="network-info-card-current-status-title">
              {t("current_status", { ns: "lift" })}
            </div>
            <div
              className={`network-info-card-current-status-content ${deviceConnected ? "connected" : "disconnected"}`}
            >
              <div className="network-info-card-current-status-info">
                {deviceConnected ? <ConnectedIcon /> : <DisconnectedIcon />}
                <div className="network-info-card-current-status-info-title">
                  {deviceConnected
                    ? t("connected", { ns: "lift" })
                    : t("disconnected", { ns: "lift" })}
                </div>
              </div>
              <div className="network-info-card-current-status-details">
                <div className="network-info-card-current-status-details-title">
                  {t("last_updated", { ns: "lift" })}
                </div>
                <div className="network-info-card-current-status-details-info">
                  -
                </div>
              </div>
            </div>
          </div>
          <CardDetailTitled
            title={t("imsi", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.imsi)}
          />
          <CardDetailTitled
            title={t("imei", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.imei)}
          />
          <CardDetailTitled
            title={t("iccid", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.iccid)}
          />
          <CardDetailTitled
            title={t("cid", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.cid)}
          />
          <CardDetailTitled
            title={t("lac", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.lac)}
          />
          <CardDetailTitled
            title={t("tac", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.tac)}
          />
          <CardDetailTitled
            title={t("timestamp", { ns: "lift" })}
            value={getDisplayValue(networkInfo?.timestamp)}
          />
        </>
      ),
    },
    {
      name: t("signal_strength", { ns: "lift" }),
      content: (
        <div className="network-info-card-signal-info">
          {signalStrengthData?.length ? (
            <LineGraph
              title={t("signal_strength", { ns: "lift" })}
              info={t("last_update_time", { ns: "lift", time: "12:29:55" })}
              data={signalStrengthData}
            />
          ) : (
            t("no_signal_data", { ns: "lift" })
          )}{" "}
        </div>
      ),
    },
  ];

  return (
    <Card title={t("network_info_title", { ns: "lift" })} onRefresh={onRefresh}>
      <Tab tabs={tabs} />
    </Card>
  );
};

const getDisplayValue = (value: string | undefined) => {
  return value && value != "null" ? value : "";
};
