import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { DeviceInfo } from "../../../models/device";
import { useEffect, useState } from "react";
import { fetchDeviceInfo } from "../../../services/devices-api";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../../services/clift-api-errors";
import { Picker } from "../../../components/Picker";
import "./DeviceTechInfoCard.css";

export interface DeviceTechInfoCardProps {
  deviceId: number | undefined;
  tenantId: number | undefined;
}

export const DeviceTechInfoCard = ({
  deviceId,
  tenantId,
}: DeviceTechInfoCardProps) => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const { t } = useTranslation();

  const updateDeviceTechInfo = () => {
    if (deviceId !== undefined && !!tenantId) {
      fetchDeviceInfo(tenantId || 0, deviceId)
        .then((devInfo) => {
          setFetchError(false);
          setLoading(false);
          setDeviceInfo(devInfo);
        })
        .catch((err) => {
          setLoading(false);
          setFetchError(true);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("data_fetch_failed", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
    console.log(fetchError);
  };

  useEffect(() => {
    updateDeviceTechInfo();
  }, [deviceId, cliftState.currentTenant]);

  const items = [
    {
      label: "X06",
      value: "X06",
    },
  ];

  return (
    <Card
      title={t("components", { ns: "lift" })}
      onRefresh={updateDeviceTechInfo}
    >
      {loading && <progress></progress>}
      {/* {fetchError && !loading && (
        <CardDetail
          value={t("lift_info_fetch_failed", { ns: "lift" })}
        ></CardDetail>
      )} */}
      {/* {deviceInfo && ( */}
      <div className="device-tech-info-card-model-picker">
        <Picker
          currentValue={"X06"}
          noCurrentText={""}
          noOptionsText={""}
          items={items}
          onSelect={() => {}}
          disabled
        />{" "}
      </div>
      <>
        <CardDetailTitled
          title={t("manufacturer", { ns: "lift" })}
          value={deviceInfo?.manufacturer ?? ""}
        />
        <CardDetailTitled
          title={t("model", { ns: "lift" })}
          value={deviceInfo?.model ?? ""}
        />
        <CardDetailTitled
          title={t("sw_version", { ns: "lift" })}
          value={deviceInfo?.swVersion ?? ""}
        />
        <CardDetailTitled
          title={t("os_name", { ns: "lift" })}
          value={deviceInfo?.osName ?? ""}
        />
        <CardDetailTitled
          title={t("processor_architecture", { ns: "lift" })}
          value={deviceInfo?.processorArchitecture ?? ""}
        />
        <CardDetailTitled
          title={t("processor_manufacturer", { ns: "lift" })}
          value={deviceInfo?.processorManufacturer ?? ""}
        />
        <CardDetailTitled
          title={t("total_storage", { ns: "lift" })}
          value={
            deviceInfo?.totalStorage !== undefined
              ? String(deviceInfo?.totalStorage)
              : ""
          }
        />
        <CardDetailTitled
          title={t("total_memory", { ns: "lift" })}
          value={
            deviceInfo?.totalMemory !== undefined
              ? String(deviceInfo?.totalMemory)
              : ""
          }
        />
      </>
      {/* )} */}
    </Card>
  );
};
