import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Panels } from "../context/clift-context/clift-context";
import { CliftReducerAction } from "../context/clift-context/clift-action-types";
import { useCliftContext } from "../hooks/useCliftContext";
import { isRoleForAction } from "../hooks/useRoles";
import { ViewTenantAllowedRoles } from "../models/role";
import { ReactComponent as SettingsIcon } from "../assets/Cog.svg";
import { ReactComponent as ProfileIcon } from "../assets/Profile.svg";
import { ReactComponent as NotificationIcon } from "../assets/Notification.svg";
import { supportedLngs } from "../app/i18nsetup";
import { Dropdown } from "./Dropdown";
import { LanguageDropdown } from "./LanguageDropDown";
import "./Topmenu.css";
import { EditTenant } from "../app/tenants/EditTenant";

export const Topmenu = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <div className="topmenu">
      <menu>
        {cliftState.tenants && cliftState.tenants.length > 0 && (
          <Dropdown
            currentId={cliftState.currentTenant?.id}
            items={
              cliftState.tenants.map((t) => ({
                label: t.name ?? "",
                id: t.id,
              })) ?? []
            }
            disabled={cliftState.tenants.length < 2}
            noCurrentText={t("select_tenant")}
            noOptionsText={t("no_tenants")}
            onSelect={(tenantId) => {
              const tenant = cliftState.tenants?.find((t) => t.id === tenantId);
              if (tenant) {
                dispatchCliftState({
                  type: CliftReducerAction.SetCurrentTenant,
                  tenant,
                });
                // Work-a-round: cannot navigate from context provider as it outside of router.
                setTimeout(() => {
                  navigate("/", { replace: true });
                }, 100);
              }
            }}
          />
        )}
        <li>
          <LanguageDropdown
            currentCode={i18n.resolvedLanguage}
            noCurrentLanguage={t("select_language")}
            noLanguageOptionsText={t("no_language")}
            disabled={false}
            items={Object.entries(supportedLngs).map(([code, name]) => ({
              code: code,
              name: name,
            }))}
            onSelect={(code) => {
              i18n.changeLanguage(code);
            }}
          />
        </li>
        <li
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.AlertPanelVisible,
              visible: !(cliftState.visiblePanels & Panels.Alerts),
            });
          }}
        >
          <NotificationIcon className="topmenu-icon" />
        </li>
        {isRoleForAction(ViewTenantAllowedRoles) && (
          <li
            onClick={() => {
              if (cliftState.currentTenant) {
                dispatchCliftState({
                  type: CliftReducerAction.SetSidePanelContent,
                  content: (
                    <EditTenant
                      tenantID={cliftState.currentTenant?.id}
                      child={false}
                    />
                  ),
                });
              }
            }}
          >
            <SettingsIcon className="topmenu-icon" />
          </li>
        )}
        <li
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.UserPanelVisible,
              visible: !(cliftState.visiblePanels & Panels.User),
            });
          }}
        >
          <ProfileIcon className="topmenu-icon" />
        </li>
      </menu>
    </div>
  );
};
