import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditFormValues } from "../../models/user";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBarProps } from "../../components/SideModalButtonBar";
import {
  StannahRole,
  RoleFormValues,
  EmployeeListEditAllowedRoles,
} from "../../models/role";
import FormField from "../../components/FormField";
import { RolesForm } from "./RolesForm";
import "./Employees.css";
import { isRoleForAction } from "../../hooks/useRoles";

export interface EditEmployeeFormProps {
  onSubmit: (roles: RoleFormValues[]) => Promise<void>;
  onClose: () => void;
  initialValues?: EditFormValues;
  setBottomButtonsProps: (buttonsProps: SideModalButtonBarProps) => void;
}

export const EditEmployeeForm = ({
  onSubmit,
  onClose,
  initialValues = {
    email: "",
    roles: [],
  },
  setBottomButtonsProps,
}: EditEmployeeFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [email] = useState(initialValues?.email);
  const [roles, setRoles] = useState<RoleFormValues[]>(
    initialValues?.roles || []
  );
  const employeeForm = useRef<HTMLFormElement>(null);
  const onHandleRoleChanged = (newRole: StannahRole, index: number) => {
    setChanged(true);
    const newRoles = roles.map((employeeRole, i) => {
      if (i === index) {
        return {
          ...employeeRole,
          role: newRole,
        };
      }
      return employeeRole;
    });
    setRoles(newRoles);
  };

  const onCancel = () => {
    if (hasChanged) {
      setCanceled(true);
    } else {
      onClose();
    }
  };

  const onSave = () => {
    onSubmit(roles);
  };

  const bottomButtonsProps = {
    onCancel: onCancel,
    onSave: onSave,
    showSave: true,
    saveDisabled:
      roles.length === 0 ||
      !isRoleForAction(EmployeeListEditAllowedRoles) ||
      !email,
  };

  useEffect(() => {
    setBottomButtonsProps(bottomButtonsProps);
  }, [email, roles]);

  return (
    <div className="employee-form">
      <form ref={employeeForm}>
        <div className="employee-form-role-page">
          <div className="employee-form-creation-row">
            <div>
              <h3>{t("employee_info", { ns: "employee" })}</h3>
              <p className="employee-form-edit-hint">
                {t("edit_employee_hint", { ns: "employee" })}
              </p>
            </div>
          </div>

          <div className="employee-form-creation-row">
            <div className="employee-form-edit-email">
              <FormField
                title={t("email", { ns: "customer" })}
                name="email"
                disabled={true}
                value={email ?? ""}
              />
            </div>
          </div>
          <div>
            <RolesForm
              handleRoleChanged={onHandleRoleChanged}
              setChanged={setChanged}
              setRoles={setRoles}
              hasEditRights={isRoleForAction(EmployeeListEditAllowedRoles)}
              roles={roles}
            />
          </div>

          <div className="employee-form-errors"></div>
        </div>
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
