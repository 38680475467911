import { ReactNode } from "react";
import { Contact } from "../../models/contact";
import { Customer, CustomerFullResponse } from "../../models/customer";
import { TenantListItem } from "../../models/tenant-list-item";
import { User } from "../../models/user";
import { ClientPrincipal } from "../../models/client-principal";
import { Device } from "models/device";

export interface BaseAction<T extends CliftReducerAction> {
  type: T;
}

export const enum CliftReducerAction {
  FetchTenants,
  FetchTenantUsers,
  SetCurrentTenant,
  SetTenants,
  AddAlert,
  AddUsers,
  AddTenant,
  FetchCustomers,
  SetCurrentCustomer,
  SetCustomers,
  AddCustomerLifts,
  AddTenantLifts,
  FetchContacts,
  SetContacts,
  EditDevice,
  CloseSidePanel,
  AlertPanelVisible,
  UserPanelVisible,
  EditLiftPhoneNumbers,
  EditSipSettings,
  EditSimSettings,
  EditDeviceSettings,
  SetFromOriginalPage,
  SetSidePanelContent,
  RemoveAllAlerts,
  RemoveAlert,
}

export type CliftActions =
  | {
      type: CliftReducerAction.FetchTenants;
    }
  | {
      type: CliftReducerAction.SetCurrentTenant;
      tenant: TenantListItem;
    }
  | {
      type: CliftReducerAction.SetTenants;
      tenants: TenantListItem[] | undefined;
    }
  | {
      type: CliftReducerAction.AddAlert;
      alert: string;
    }
  | {
      type: CliftReducerAction.AddUsers;
      users: User[];
    }
  | {
      type: CliftReducerAction.FetchTenantUsers;
      tenantID: number;
    }
  | {
      type: CliftReducerAction.AddTenant;
    }
  | {
      type: CliftReducerAction.SetCurrentCustomer;
      customer: Customer;
    }
  | {
      type: CliftReducerAction.AddCustomerLifts;
      customer: CustomerFullResponse;
    }
  | {
      type: CliftReducerAction.AddTenantLifts;
      tenantID: number;
    }
  | {
      type: CliftReducerAction.FetchContacts;
      tenantID: number | undefined;
      customerID: number;
    }
  | {
      type: CliftReducerAction.SetContacts;
      contacts: Contact[];
    }
  | {
      type: CliftReducerAction.EditDevice;
      device: Device;
      deviceID: number;
    }
  | {
      type: CliftReducerAction.CloseSidePanel;
    }
  | {
      type: CliftReducerAction.AlertPanelVisible;
      visible: boolean;
    }
  | {
      type: CliftReducerAction.UserPanelVisible;
      visible: boolean;
    }
  | {
      type: CliftReducerAction.EditSipSettings;
      tenantID: number;
      device: Device;
    }
  | {
      type: CliftReducerAction.EditSimSettings;
      tenantID: number;
      device: Device;
    }
  | {
      type: CliftReducerAction.EditDeviceSettings;
      tenantID: number;
      device: Device;
    }
  | {
      type: CliftReducerAction.SetFromOriginalPage;
      fromOriginalPage: string | undefined;
    }
  | {
      type: CliftReducerAction.SetSidePanelContent;
      content: ReactNode | undefined;
    }
  | {
      type: CliftReducerAction.RemoveAlert;
      alertId: number;
    }
  | {
      type: CliftReducerAction.RemoveAllAlerts;
    };

export interface CliftContextState {
  currentTenant?: TenantListItem | undefined;
  tenants: TenantListItem[] | undefined;
  alerts: string[];
  currentCustomer?: Customer | undefined;
  users: User[];
  contacts: Contact[];
  sidepanelContent?: ReactNode | undefined;
  tenantFetch: { [index: number]: Promise<void> };
  visiblePanels: number;
  fromOriginalPage?: string | undefined;
}

export interface CliftContextInterface {
  currentUser: ClientPrincipal | null;
  createTenantPanelVisible: boolean;
  setCreateTenantPanelVisible: (value: boolean) => void;
  cliftState: CliftContextState;
  dispatchCliftState: React.Dispatch<CliftActions>;
}
