import { Picker, Option } from "../../components/Picker";
import { StannahRole, RoleFormValues, getRoleList } from "../../models/role";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useEffect, useState } from "react";

export interface RolesFormProps {
  handleRoleChanged: (newRole: StannahRole, index: number) => void;
  setChanged: (changed: boolean) => void;
  setRoles: (roles: RoleFormValues[]) => void;
  hasEditRights: boolean;
  roles: RoleFormValues[];
}

export const RolesForm = ({
  handleRoleChanged,
  setChanged,
  setRoles,
  hasEditRights,
  roles,
}: RolesFormProps) => {
  const { cliftState } = useCliftContext();
  const { t } = useTranslation();

  const [roleList, setRoleList] = useState<StannahRole[]>([]);
  const [filteredRoleList, setFilteredRoleList] = useState<StannahRole[]>([]);

  useEffect(() => {
    const roleListByTenantType = getRoleList(
      cliftState.currentTenant?.tenantType
    );
    setRoleList(roleListByTenantType);
  }, [cliftState.currentTenant]);

  useEffect(() => {
    if (roles.length > 0) {
      const filteredRoleList = roleList.filter(
        (role) => !roles.some((selectedRole) => selectedRole.role === role)
      );

      setFilteredRoleList(filteredRoleList);
    }
  }, [roles, roleList]);

  const items = roleList.map((role) => {
    const isAvailable = filteredRoleList.includes(role);
    return {
      label: t(role, { ns: "role" }),
      value: role,
      isDisabled: !isAvailable,
    } as Option<StannahRole>;
  });

  return (
    <div className="employee-form-role-form">
      <div className="employee-form-creation-row">
        <div className="employee-form-role-title">
          {t("role", { ns: "employee" })}
        </div>
      </div>
      {(roles || []).map((role, i) => (
        <div key={i} className="employee-form-role-employees">
          <Picker
            noCurrentText={t("select_role", { ns: "employee" })}
            noOptionsText={t("no_roles_to_select", { ns: "employee" })}
            items={items}
            disabled={!hasEditRights}
            currentValue={role.role ?? ("" as StannahRole)}
            onSelect={function (role: StannahRole): void {
              setChanged(true);
              handleRoleChanged(role, i);
            }}
          />
          <button
            className="button employee-form-role-delete-role-button"
            title={t("delete", { ns: "translation" })}
            type="button"
            disabled={!hasEditRights || roles.length === 1}
            onClick={(e) => {
              e.preventDefault();
              setRoles(roles.toSpliced(i, 1));
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}

      {roles.length !== roleList.length && (
        <button
          className="button secondary-button"
          type="button"
          disabled={!hasEditRights}
          onClick={(e) => {
            e.preventDefault();
            setRoles([
              ...(roles || []),
              {
                tenantId: cliftState.currentTenant?.id,
                role: undefined,
              },
            ]);
          }}
        >
          <PlusIcon />
          {t("add_new_role", { ns: "employee" })}
        </button>
      )}
    </div>
  );
};
